export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.path.startsWith('/precheckins/iframepages')) {
    return
  }

  // TODO: maybe use routes instead of the layout

  // Skip for the client side, and for now for the precheckin and reservations layout
  if (
    import.meta.client &&
    to.meta.layout !== 'login' &&
    to.meta.layout !== 'account'
  )
    return

  // Check the authentication status
  const isAuthenticated = await useAuth().isAuthenticated
  const unauthenticatedRoutes = ['/login', '/register', '/forgotpassword']
  const neutralRoutes = ['/resetpassword', 'verify']

  // If the user wants to access a neutral route it doesn't matter if the user is authenticated
  if (neutralRoutes.find((route) => to.path.startsWith(route))) return

  // If the user want to the unauthenticated routes, and is authenticated, redirect to the home page
  const pathIsInUnauthenticatedRoutes = unauthenticatedRoutes.find((route) =>
    to.path.startsWith(route),
  )
  if (pathIsInUnauthenticatedRoutes && isAuthenticated) {
    return navigateTo({ path: '/', query: from.query }, { external: true })
  }

  // Left is the scenario where the user is not authenticated and wants to navigate to a page that requires authentication
  if (to.meta.layout === 'account' && !isAuthenticated) {
    return navigateTo({ path: '/login', query: from.query })
  }

  // If the user is not authenticated, return and don't load the user
  if (!isAuthenticated) return

  // Get the user if an authentication is there, but skip it for the callback route
  if (to.path.startsWith('/callback')) return

  // Now get the user
  const user = useUser()
  try {
    await user.load()
  } catch (error) {
    console.error('No user found. ', error)
  }
})
