
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as callbackOV44qusMMrMeta } from "/myapp/pages/callback.vue?macro=true";
import { default as forgotpasswordumHyGfPiPoMeta } from "/myapp/pages/forgotpassword.vue?macro=true";
import { default as indexhCckWuoxrfMeta } from "/myapp/pages/index.vue?macro=true";
import { default as livenessCFrYOxs08xMeta } from "/myapp/pages/liveness.vue?macro=true";
import { default as loginVythfWRpGUMeta } from "/myapp/pages/login.vue?macro=true";
import { default as bookingsrUSuwni712Meta } from "/myapp/pages/myaccount/bookings.vue?macro=true";
import { default as index0xOjBSU9g5Meta } from "/myapp/pages/myaccount/index.vue?macro=true";
import { default as profilecqFBbv6Jk4Meta } from "/myapp/pages/myaccount/profile.vue?macro=true";
import { default as arrivalNCtVFEmtQSMeta } from "/myapp/pages/precheckins/[token]/arrival.vue?macro=true";
import { default as checkinbNRqEerptkMeta } from "/myapp/pages/precheckins/[token]/checkin.vue?macro=true";
import { default as customerDfXZ11WphRMeta } from "/myapp/pages/precheckins/[token]/customer.vue?macro=true";
import { default as expiredbi6Oe1qLvpMeta } from "/myapp/pages/precheckins/[token]/expired.vue?macro=true";
import { default as paxZu33OHJwFsMeta } from "/myapp/pages/precheckins/[token]/pax.vue?macro=true";
import { default as services8PD8N2mMxdMeta } from "/myapp/pages/precheckins/[token]/services.vue?macro=true";
import { default as travellerslQloI1PJwUMeta } from "/myapp/pages/precheckins/[token]/travellers.vue?macro=true";
import { default as _91token_93uzECh2QBVmMeta } from "/myapp/pages/precheckins/[token].vue?macro=true";
import { default as failuretQszlETj5rMeta } from "/myapp/pages/precheckins/iframepages/failure.vue?macro=true";
import { default as successpendingtsSb5GyhQbMeta } from "/myapp/pages/precheckins/iframepages/successpending.vue?macro=true";
import { default as index78Fnnj1Za2Meta } from "/myapp/pages/precheckins/index.vue?macro=true";
import { default as readinessMnHOvBd5wMMeta } from "/myapp/pages/readiness.vue?macro=true";
import { default as registerFMUtbFkndlMeta } from "/myapp/pages/register.vue?macro=true";
import { default as cancellationFsimX4idS6Meta } from "/myapp/pages/reservations/[token]/cancellation.vue?macro=true";
import { default as indexKnGPYG6pUJMeta } from "/myapp/pages/reservations/[token]/index.vue?macro=true";
import { default as paxHO6NisqDqpMeta } from "/myapp/pages/reservations/[token]/pax.vue?macro=true";
import { default as payment4Jg2r68bDWMeta } from "/myapp/pages/reservations/[token]/payment.vue?macro=true";
import { default as precheckin2YtqejIM4RMeta } from "/myapp/pages/reservations/[token]/precheckin.vue?macro=true";
import { default as refundeKI05EVp8mMeta } from "/myapp/pages/reservations/[token]/refund.vue?macro=true";
import { default as reviewnZFzIvsZniMeta } from "/myapp/pages/reservations/[token]/review.vue?macro=true";
import { default as servicesSbMrjNFw3fMeta } from "/myapp/pages/reservations/[token]/services.vue?macro=true";
import { default as _91token_93YtsVIohqD9Meta } from "/myapp/pages/reservations/[token].vue?macro=true";
import { default as indexgdnMEC9QcHMeta } from "/myapp/pages/reservations/index.vue?macro=true";
import { default as resetpasswordOCx1YwvoTNMeta } from "/myapp/pages/resetpassword.vue?macro=true";
import { default as verifyUhqqiEzCMzMeta } from "/myapp/pages/verify.vue?macro=true";
export default [
  {
    name: "callback",
    path: "/callback",
    meta: callbackOV44qusMMrMeta || {},
    component: () => import("/myapp/pages/callback.vue")
  },
  {
    name: "forgotpassword",
    path: "/forgotpassword",
    meta: forgotpasswordumHyGfPiPoMeta || {},
    component: () => import("/myapp/pages/forgotpassword.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/myapp/pages/index.vue")
  },
  {
    name: "liveness",
    path: "/liveness",
    component: () => import("/myapp/pages/liveness.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginVythfWRpGUMeta || {},
    component: () => import("/myapp/pages/login.vue")
  },
  {
    name: "myaccount-bookings",
    path: "/myaccount/bookings",
    meta: bookingsrUSuwni712Meta || {},
    component: () => import("/myapp/pages/myaccount/bookings.vue")
  },
  {
    name: "myaccount",
    path: "/myaccount",
    meta: index0xOjBSU9g5Meta || {},
    component: () => import("/myapp/pages/myaccount/index.vue")
  },
  {
    name: "myaccount-profile",
    path: "/myaccount/profile",
    meta: profilecqFBbv6Jk4Meta || {},
    component: () => import("/myapp/pages/myaccount/profile.vue")
  },
  {
    name: "precheckins-token",
    path: "/precheckins/:token()",
    meta: _91token_93uzECh2QBVmMeta || {},
    component: () => import("/myapp/pages/precheckins/[token].vue"),
    children: [
  {
    name: "precheckins-token-arrival",
    path: "arrival",
    component: () => import("/myapp/pages/precheckins/[token]/arrival.vue")
  },
  {
    name: "precheckins-token-checkin",
    path: "checkin",
    component: () => import("/myapp/pages/precheckins/[token]/checkin.vue")
  },
  {
    name: "precheckins-token-customer",
    path: "customer",
    component: () => import("/myapp/pages/precheckins/[token]/customer.vue")
  },
  {
    name: "precheckins-token-expired",
    path: "expired",
    component: () => import("/myapp/pages/precheckins/[token]/expired.vue")
  },
  {
    name: "precheckins-token-pax",
    path: "pax",
    component: () => import("/myapp/pages/precheckins/[token]/pax.vue")
  },
  {
    name: "precheckins-token-services",
    path: "services",
    component: () => import("/myapp/pages/precheckins/[token]/services.vue")
  },
  {
    name: "precheckins-token-travellers",
    path: "travellers",
    component: () => import("/myapp/pages/precheckins/[token]/travellers.vue")
  }
]
  },
  {
    name: "precheckins-iframepages-failure",
    path: "/precheckins/iframepages/failure",
    component: () => import("/myapp/pages/precheckins/iframepages/failure.vue")
  },
  {
    name: "precheckins-iframepages-successpending",
    path: "/precheckins/iframepages/successpending",
    component: () => import("/myapp/pages/precheckins/iframepages/successpending.vue")
  },
  {
    name: "precheckins",
    path: "/precheckins",
    component: () => import("/myapp/pages/precheckins/index.vue")
  },
  {
    name: "readiness",
    path: "/readiness",
    component: () => import("/myapp/pages/readiness.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerFMUtbFkndlMeta || {},
    component: () => import("/myapp/pages/register.vue")
  },
  {
    name: _91token_93YtsVIohqD9Meta?.name,
    path: "/reservations/:token()",
    meta: _91token_93YtsVIohqD9Meta || {},
    component: () => import("/myapp/pages/reservations/[token].vue"),
    children: [
  {
    name: "reservations-token-cancellation",
    path: "cancellation",
    component: () => import("/myapp/pages/reservations/[token]/cancellation.vue")
  },
  {
    name: "reservations-token",
    path: "",
    component: () => import("/myapp/pages/reservations/[token]/index.vue")
  },
  {
    name: "reservations-token-pax",
    path: "pax",
    component: () => import("/myapp/pages/reservations/[token]/pax.vue")
  },
  {
    name: "reservations-token-payment",
    path: "payment",
    component: () => import("/myapp/pages/reservations/[token]/payment.vue")
  },
  {
    name: "reservations-token-precheckin",
    path: "precheckin",
    component: () => import("/myapp/pages/reservations/[token]/precheckin.vue")
  },
  {
    name: "reservations-token-refund",
    path: "refund",
    component: () => import("/myapp/pages/reservations/[token]/refund.vue")
  },
  {
    name: "reservations-token-review",
    path: "review",
    component: () => import("/myapp/pages/reservations/[token]/review.vue")
  },
  {
    name: "reservations-token-services",
    path: "services",
    component: () => import("/myapp/pages/reservations/[token]/services.vue")
  }
]
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/myapp/pages/reservations/index.vue")
  },
  {
    name: "resetpassword",
    path: "/resetpassword",
    meta: resetpasswordOCx1YwvoTNMeta || {},
    component: () => import("/myapp/pages/resetpassword.vue")
  },
  {
    name: "verify",
    path: "/verify",
    meta: verifyUhqqiEzCMzMeta || {},
    component: () => import("/myapp/pages/verify.vue")
  }
]