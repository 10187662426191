export function useStores() {
  return useNuxtApp().$stores
}

export const useAuth = () => useStores().auth
export const useBookmarks = () => useStores().bookmarks
export const useConfdata = () => useStores().confdata
export const useConsent = () => useStores().consent
export const useCookies = () => useStores().cookies
export const useIDs = () => useStores().ids
export const usePartner = () => useStores().partner
export const usePayment = () => useStores().payment
export const useReservation = () => useStores().reservation
export const usePrecheckin = () => useStores().precheckin
export const useReviews = () => useStores().reviews
export const useScreens = () => useStores().screens
export const useToggler = () => useStores().toggler
export const useTracking = () => useStores().tracking
export const useUser = () => useStores().user
export const useUseragent = () => useStores().useragent
export const useUserMobileMenu = () => useStores().userMobileMenu
export const useValidation = () => useStores().validation
