<template>
  <client-only>
    <Teleport v-if="useStorageReady()" to="body">
      <div
        id="consent"
        class="relative flex items-center justify-center text-base"
      >
        <ConsentConfig
          v-if="configIsOpen || useConsent().configOpen"
          :statistics="consent.statistics"
          :other="consent.other"
          @close="closeConfig"
          @save-choice="saveChoice"
        />
        <ConsentBanner
          v-if="useConsent().open"
          :open-config="openConfig"
          :open-privacypolicy="openPrivacypolicy"
          :config-is-open="configIsOpen"
          @save-choice="saveChoice"
        />
      </div>
    </Teleport>
  </client-only>
</template>

<script setup lang="ts">
const configIsOpen = ref(false)
const decision = ref(false)
const consent: Ref<Consent> = ref({
  statistics: false,
  other: false,
  initDecision: false,
})

defineExpose({ decision, openConfig, openPrivacypolicy })

const pplink = computed(() => {
  return (
    useConfdata().navigation?.links.privacyPolicy?.href ||
    'https://www.interhome.group/privacy-policy'
  )
})

function closeConfig() {
  configIsOpen.value = false
  useConsent().configOpen = false
}

function openConfig() {
  configIsOpen.value = true
}

function openPrivacypolicy() {
  window.location.href = pplink.value
}

function saveChoice(opts: Choice) {
  const value = {
    timestamp: new Date(),
    statistics: opts.statistics,
    other: opts.other,
    initDecision: consent.value.initDecision === null,
  }
  useConsent().update(value)
  configIsOpen.value = false
  useConsent().configOpen = false
}
</script>
