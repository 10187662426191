import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAddToAccountToaster, LazyReservationButton, LazyWebccBadge, LazyWebccButton, LazyWebccCompanyLogo, LazyWebccInterchaletBrandMergeToaster, LazyWebccLink, LazyWebccLoading, LazyWebccModal, LazyWebccOneTimePassword, LazyWebccOrDivider, LazyWebccStepper, LazyWebccStepperButton, LazyWebccCheckbox, LazyWebccDatepicker, LazyWebccForm, LazyWebccFormfield, LazyWebccInput, LazyWebccRadio, LazyWebccSelect, LazyWebccSwitch, LazyWebccTextarea } from '#components'
const lazyGlobalComponents = [
  ["AddToAccountToaster", LazyAddToAccountToaster],
["ReservationButton", LazyReservationButton],
["WebccBadge", LazyWebccBadge],
["WebccButton", LazyWebccButton],
["WebccCompanyLogo", LazyWebccCompanyLogo],
["WebccInterchaletBrandMergeToaster", LazyWebccInterchaletBrandMergeToaster],
["WebccLink", LazyWebccLink],
["WebccLoading", LazyWebccLoading],
["WebccModal", LazyWebccModal],
["WebccOneTimePassword", LazyWebccOneTimePassword],
["WebccOrDivider", LazyWebccOrDivider],
["WebccStepper", LazyWebccStepper],
["WebccStepperButton", LazyWebccStepperButton],
["WebccCheckbox", LazyWebccCheckbox],
["WebccDatepicker", LazyWebccDatepicker],
["WebccForm", LazyWebccForm],
["WebccFormfield", LazyWebccFormfield],
["WebccInput", LazyWebccInput],
["WebccRadio", LazyWebccRadio],
["WebccSelect", LazyWebccSelect],
["WebccSwitch", LazyWebccSwitch],
["WebccTextarea", LazyWebccTextarea],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
