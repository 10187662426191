import { skipHydrate } from 'pinia'

const STORAGE_KEY = 'CONSENT'

export const useConsentStore = defineStore('consent', () => {
  const consent: Ref<Consent> = useLocalStorage(STORAGE_KEY, {})
  const open = computed(() => {
    return !consent.value.timestamp
  })
  const configOpen: Ref<boolean> = ref(false)

  function update(value: Consent) {
    consent.value = value
    // @ts-expect-ignore
    if (useTracking) {
      useTracking().trackConsent(value)
    }
    if ((window as any)?.gtag) {
      ;(window as any).gtag('consent', 'update', {
        ad_user_data: value.other ? 'granted' : 'denied', // maps to Marketing Cookies
        ad_personalization: value.other ? 'granted' : 'denied', // maps to Marketing Cookies
        ad_storage: value.other ? 'granted' : 'denied', // maps to Marketing Cookies
        analytics_storage: value.statistics ? 'granted' : 'denied', // maps to Analytics Cookies
      })

      if (!value.statistics) {
        ;(window as any).gtag('set', 'url_passthrough', true)
      }
    }
  }

  return { consent: skipHydrate(consent), open, configOpen, update }
})
