export default {
  deleteAccount,
  manageBookings,
  login,
  register,
  verify,
  openDownload,
  downloadDoc,
  downloadAllDocs,
}

function deleteAccount() {
  useTracking().send({
    event: 'delete_account',
    action: 'delete',
    label: 'Delete Account',
    category: undefined,
    component: 'button',
    position: 'my-account',
    language: useConfdata().language,
    _clear: true,
  })
}

function manageBookings() {
  useTracking().send({
    event: 'manage_bookings',
    action: 'manage',
    label: 'Manage booking',
    category: undefined,
    component: 'my-booking-settings',
    position: 'my-bookings',
    language: useConfdata().language,
    _clear: true,
  })
}

function login() {
  useTracking().send({
    event: 'login',
    action: 'login success',
    label: 'Sign in',
    category: undefined,
    component: 'login-entry-mask',
    position: 'login-page',
    language: useConfdata().language,
    _clear: true,
  })
}

function register() {
  useTracking().send({
    event: 'register_account',
    action: 'register',
    label: 'Registration',
    category: undefined,
    component: 'register-mask',
    position: 'register-page',
    language: useConfdata().language,
    _clear: true,
  })
}

function verify() {
  useTracking().send({
    event: 'register_account',
    action: 'verify',
    label: 'Verification',
    category: undefined,
    component: 'register-mask',
    position: 'verify-page',
    language: useConfdata().language,
    _clear: true,
  })
}

function openDownload() {
  useTracking().send({
    event: 'document_download',
    action: 'open',
    label: 'Download Documents',
    category: undefined,
    component: 'document-download',
    position: undefined,
    language: useConfdata().language,
    _clear: true,
  })
}

function downloadDoc(type: string) {
  useTracking().send({
    event: 'document_download',
    action: 'download',
    label: `Download ${type}`, // Download Invoice | Download Voucher | Download payment confirmation | Download all
    category: undefined,
    component: 'document-download',
    position: 'popup',
    language: useConfdata().language,
    _clear: true,
  })
}

function downloadAllDocs() {
  useTracking().send({
    event: 'document_download',
    action: 'download',
    label: 'Download All',
    category: undefined,
    component: 'document-download',
    position: 'popup',
    language: useConfdata().language,
    _clear: true,
  })
}
