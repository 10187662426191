import type { Pinia } from 'pinia'
import { usePrecheckinStore } from '~/composables/usePrecheckinStore'
import { useReservationStore } from '~/composables/useReservationStore'
import { useReviewsStore } from '~/composables/useReviewsStore'
import { usePaymentStore } from '~/composables/usePaymentStore'
import { useScreenStore } from '~/composables/useScreenStore'
import { useUserStore } from '~/composables/useUserStore'
import { useUserMobileMenuStore } from '~/composables/useUserMobileMenuStore'
import { useValidationStore } from '~/composables/useValidationStore'
import { useCookiesStore } from '~/composables/@ihgroup/useCookiesStore'
import { useIDsStore } from '~/composables/@ihgroup/useIDsStore'
import { usePartnerStore } from '~/composables/@ihgroup/usePartnerStore'
import { useTogglerStore } from '~/composables/@ihgroup/useTogglerStore'
import { useTrackingStore } from '~/composables/@ihgroup/useTrackingStore'

const storesOrder = [
  // no dependencies
  ['bookmarks', useBookmarksStore] as const,
  ['consent', useConsentStore] as const,
  ['cookies', useCookiesStore] as const,
  ['ids', useIDsStore] as const,
  ['payment', usePaymentStore] as const,
  ['screen', useScreenStore] as const,
  ['toggler', useTogglerStore] as const,
  ['userMobileMenu', useUserMobileMenuStore] as const,
  ['validation', useValidationStore] as const,

  ['auth', useAuthStore] as const, // cookies
  ['confdata', useConfdataStore] as const, // params
  ['partner', usePartnerStore] as const, // params
  ['reservation', useReservationStore] as const, // params
  ['precheckin', usePrecheckinStore] as const, // confdata, reservation
  ['reviews', useReviewsStore] as const, // confdata, params
  ['tracking', useTrackingStore] as const, // consent, cookies, IDs
  ['user', useUserStore] as const, // auth, confdata, reservation
  ['useragent', useUseragentStore] as const, // params
] as const

function mutateStores(stores: Record<string, unknown>, pinia: Pinia) {
  for (const [name, storeFn] of storesOrder) {
    Object.assign(stores, { [name]: storeFn(pinia) })
  }
  return stores
}

const piniaStorePlugin = defineNuxtPlugin({
  name: 'stores',
  enforce: 'pre',

  dependsOn: ['pinia', 'app:i18n', 'app:params'],

  setup(nuxtApp) {
    const $pinia = nuxtApp.$pinia as Pinia
    let $stores = {}
    Object.defineProperty(nuxtApp.vueApp, '$stores', { get: () => $stores })
    nuxtApp.provide('stores', $stores)
    mutateStores($stores, $pinia)
    $stores = Object.freeze($stores)
  },
})

export default piniaStorePlugin

interface StoresMap {
  auth: ReturnType<typeof useAuthStore>
  bookmarks: ReturnType<typeof useBookmarksStore>
  confdata: ReturnType<typeof useConfdataStore>
  consent: ReturnType<typeof useConsentStore>
  cookies: ReturnType<typeof useCookiesStore>
  ids: ReturnType<typeof useIDsStore>
  partner: ReturnType<typeof usePartnerStore>
  payment: ReturnType<typeof usePaymentStore>
  reservation: ReturnType<typeof useReservationStore>
  precheckin: ReturnType<typeof usePrecheckinStore>
  reviews: ReturnType<typeof useReviewsStore>
  screens: ReturnType<typeof useScreenStore>
  toggler: ReturnType<typeof useTogglerStore>
  tracking: ReturnType<typeof useTrackingStore>
  user: ReturnType<typeof useUserStore>
  useragent: ReturnType<typeof useUseragentStore>
  userMobileMenu: ReturnType<typeof useUserMobileMenuStore>
  validation: ReturnType<typeof useValidationStore>
}

declare module '#app' {
  interface NuxtApp {
    $stores: StoresMap
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $stores: StoresMap
  }
}
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $stores: StoresMap
  }
}
