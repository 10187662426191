import type {
  Session,
  LoginFlow,
  RegistrationFlow,
  RecoveryFlow,
  SuccessfulNativeLogin,
  UpdateLoginFlowBody,
  SuccessfulNativeRegistration,
  LogoutFlow,
  UiNodeInputAttributes,
  UpdateRegistrationFlowBody,
  UpdateRecoveryFlowBody,
  VerificationFlow,
  UpdateVerificationFlowBody,
  SettingsFlow,
  FrontendApiUpdateSettingsFlowRequest,
  UpdateSettingsFlowBody,
} from '@ory/client'
import { FrontendApi, Configuration } from '@ory/client'

export const useAuthStore = defineStore('auth', () => {
  const orySessionCookie = useCookie(
    useRuntimeConfig().public.ory.sessionCookieName,
  )
  const orySessionCookieAvailable = useState(
    'ory-session-cookie-available',
    () => !!orySessionCookie.value,
  )

  // Get the ory API dynamically
  const ory = new FrontendApi(
    new Configuration({
      basePath: getOryAPIUrl(),
      baseOptions: {
        // Ensures we send cookies and all other headers in the CORS requests.
        withCredentials: true,
      },
    }),
  )

  const session: Ref<Session | undefined> = ref(undefined)
  const authenticationMethod: Ref<string | undefined> = ref(undefined)
  const csrfToken: Ref<string | undefined> = ref(undefined)
  const changedPasswordTimer: Ref<number> = ref(0)

  function getOryAPIUrl() {
    const { hostname } = useRequestURL()

    const routes = {
      localhost: 'http://localhost:4000', // Ory tunnel
      'webcc.hpk8s.com': 'https://ory-auth.webcc.hpk8s.com',
      'interhome.hpk8s.com': 'https://ory-auth.interhome.hpk8s.com',
      '*': `https://ory-auth.${hostname.replace(/^www\./, '')}`,
    }

    return (
      Object.entries(routes).find(([pattern]) =>
        hostname.includes(pattern),
      )?.[1] ?? routes['*']
    )
  }

  // Computed property that determines, if the user is logged in
  const isAuthenticated = computed(async () => {
    if (session.value) {
      // Check for the auth method
      authenticationMethod.value = getAuthenticationMethodFromSession()
      return true
    }

    // To traffic and latency, Ory asked not to call the `/whoami` endpoint if the session cookie is missing.
    // See https://jira.migros.net/browse/IHGWEBCC-1632
    if (!orySessionCookieAvailable.value) {
      return false
    }

    // If the session is not loaded, load the session and check if it is still undefined
    await loadSession()
    // If the session is still undefined, return false and set the authentication method to undefined
    if (!session.value) {
      authenticationMethod.value = undefined
      return false
    }
    authenticationMethod.value = getAuthenticationMethodFromSession()
    return true
  })

  // Gets the authentication method from the session
  function getAuthenticationMethodFromSession() {
    if (session.value) {
      const authMethod = session.value.authentication_methods
      if (authMethod) {
        return authMethod[0].method
      }
    }
    return undefined
  }

  async function loadSession() {
    const headers = useRequestHeaders(['cookie'])
    try {
      const data = await $fetch<Session>('/guestworld-api/v1/users/session', {
        headers,
        credentials: 'include',
      })
      if (data) {
        session.value = data
      }
    } catch (error) {
      console.error(
        'error while loading session or no session available',
        error,
      )
      session.value = undefined
    }
  }

  // Checks if the session is expired of not
  function isSessionExpired() {
    if (session.value && session.value.expires_at) {
      // Returns a utc timestamp of the session expiration
      const expiresAt = new Date(session.value.expires_at).getTime()

      // Get utc timestamp of the current time
      const currentTime = new Date().getTime()

      // If the session is expired, return true
      if (expiresAt < currentTime) {
        return true
      }
    }
    return false
  }

  // Gets the logout data including the url from Ory
  async function getLogoutData() {
    let logoutData: LogoutFlow | undefined
    await ory
      .createBrowserLogoutFlow()
      .then((response) => {
        logoutData = response.data
      })
      .catch((error) => {
        // console.log('error while getting logout url: ', error)
        throw error
      })

    return logoutData
  }

  // Logs the user out
  async function logoutUser(token: string) {
    await ory.updateLogoutFlow({ token }).catch((error) => {
      // console.log('error updating logout flow', error)
      throw error
    })
    session.value = undefined
  }

  async function performLogout() {
    const logoutData: LogoutFlow | undefined = await getLogoutData()
    if (!logoutData) throw new Error('No logout data available')
    // perform the logout and then emit
    await logoutUser(logoutData.logout_token)
    session.value = undefined
  }

  // Sets the session to undefined
  function resetSession() {
    session.value = undefined
  }

  // Gets the login flow data from Ory
  async function getLoginFlowData(
    returnToParameter: string,
    refresh: boolean = false,
  ) {
    let loginFlow: LoginFlow | undefined
    // The return to url is needed for the social login to navigate back to our callback page
    const returnTo = `${origin}/callback?returnTo=${createReturnToUrl(
      returnToParameter,
      useRequestURL().origin,
    )}`

    await ory
      .createBrowserLoginFlow({ refresh, returnTo })
      .then((response) => {
        loginFlow = response.data
      })
      .catch((error) => {
        // console.log('error creating login flow', error)
        throw error
      })

    // Get the csrf_token from the response by ranging over the ui.nodes and finding the csrf_token node
    if (loginFlow && loginFlow.ui?.nodes) {
      const csrfNode = loginFlow.ui.nodes.find(
        (node) =>
          node.type === 'input' &&
          (node.attributes as UiNodeInputAttributes).name === 'csrf_token',
      )
      if (csrfNode) {
        csrfToken.value = (csrfNode.attributes as UiNodeInputAttributes).value
      }
    }

    return loginFlow
  }

  // Gets the register flow data from Ory
  async function getRegisterFlowData(returnToParameter: string) {
    let registerFlow: RegistrationFlow | undefined
    // The return to url is needed for the social login to navigate back to our callback page
    const returnTo = `${
      useRequestURL().origin
    }/callback?returnTo=${createReturnToUrl(
      returnToParameter,
      useRequestURL().origin,
    )}`

    await ory
      .createBrowserRegistrationFlow({ returnTo })
      .then((response) => {
        registerFlow = response.data
      })
      .catch((error) => {
        // console.log('error while getting register flow data: ', error)
        throw error
      })

    // Get the csrf_token from the response by ranging over the ui.nodes and finding the csrf_token node
    if (registerFlow && registerFlow.ui?.nodes) {
      const csrfNode = registerFlow.ui.nodes.find(
        (node) =>
          node.type === 'input' &&
          (node.attributes as UiNodeInputAttributes).name === 'csrf_token',
      )
      if (csrfNode) {
        csrfToken.value = (csrfNode.attributes as UiNodeInputAttributes).value
      }
    }

    return registerFlow
  }

  // Gets the verification flow data from Ory
  async function getVerificationFlowData() {
    let verificationFlow: VerificationFlow | undefined

    await ory
      .createBrowserVerificationFlow()
      .then((response) => {
        verificationFlow = response.data
      })
      .catch((error) => {
        // console.log('error while getting verification flow data: ', error)
        throw error
      })

    // Get the crsf_token from the response by ranging over the ui.nodes and finding the csrf_token node
    if (verificationFlow && verificationFlow.ui?.nodes) {
      const csrfNode = verificationFlow.ui.nodes.find(
        (node) =>
          node.type === 'input' &&
          (node.attributes as UiNodeInputAttributes).name === 'csrf_token',
      )
      if (csrfNode) {
        csrfToken.value = (csrfNode.attributes as UiNodeInputAttributes).value
      }
    }

    return verificationFlow
  }

  // Logs the user in with the given credentials
  async function loginUser(flowID: string, body: UpdateLoginFlowBody) {
    // Update the login data
    body.csrf_token = csrfToken.value

    // return data.res
    let loginData: SuccessfulNativeLogin | undefined

    await ory
      .updateLoginFlow({ flow: flowID, updateLoginFlowBody: body })
      .then((response) => {
        loginData = response.data
      })
      .catch((error) => {
        if (error.response.status === 400) {
          // This error indicates, that the user is already logged in, so we can ignore it and only happens with the 400 bad request response
          const parsedMessage = error.response.data.ui.messages[0].text
          if (parsedMessage.includes('valid session was detected')) {
            // This error indicates, that i am logged in on another device, so we can ignore it
            return loginData
          }
        }
        // console.log('error updating login flow', error)
        throw error
      })

    // Set the session
    session.value = loginData?.session

    return loginData
  }

  // Creates the login flow and also directly logs the user in with the given credentials
  async function performLogin(
    email: string,
    password: string,
    returnToParameter: string,
    refresh: boolean = false,
  ) {
    const loginFlow = await getLoginFlowData(returnToParameter, refresh)
    if (!loginFlow) throw new Error('No login flow available')

    const loginData: UpdateLoginFlowBody = {
      identifier: email,
      password,
      method: 'password',
    }

    const loginResponse = await loginUser(loginFlow.id, loginData)
    if (!loginResponse) throw new Error('No login data available')

    return loginData
  }

  // Registers the user with the given credentials
  async function registerUser(
    flowID: string,
    body: UpdateRegistrationFlowBody,
  ) {
    // Update the registration data
    body.csrf_token = csrfToken.value

    let registerData: SuccessfulNativeRegistration | undefined

    // return data.res
    await ory
      .updateRegistrationFlow({
        flow: flowID,
        updateRegistrationFlowBody: body,
      })
      .then((response) => {
        registerData = response.data
      })
      .catch((error) => {
        // console.log('error updating registration flow', error)
        throw error
      })

    // Set the session
    session.value = registerData?.session

    return registerData
  }

  // Complete the verification flow
  async function completeVerificationFlow(
    flowID: string,
    body: UpdateVerificationFlowBody,
    token: string | undefined = undefined,
  ) {
    body.csrf_token = csrfToken.value

    let verificationData: VerificationFlow | undefined

    await ory
      .updateVerificationFlow({
        flow: flowID,
        updateVerificationFlowBody: body,
        token,
      })
      .then((response) => {
        verificationData = response.data
      })
      .catch((error) => {
        // console.log('error updating verification flow', error)
        throw error
      })

    return verificationData
  }

  // Gets the recovery flow data from Ory
  async function getRecoveryFlowData() {
    let recoveryFlow: RecoveryFlow | undefined

    await ory
      .createBrowserRecoveryFlow()
      .then((response) => {
        recoveryFlow = response.data
      })
      .catch((error) => {
        // console.log('error while getting recovery flow data: ', error)
        throw error
      })

    // Get the csrf_token from the response by ranging over the ui.nodes and finding the csrf_token node
    if (recoveryFlow && recoveryFlow.ui?.nodes) {
      const csrfNode = recoveryFlow.ui.nodes.find(
        (node) =>
          node.type === 'input' &&
          (node.attributes as UiNodeInputAttributes).name === 'csrf_token',
      )
      if (csrfNode) {
        csrfToken.value = (csrfNode.attributes as UiNodeInputAttributes).value
      }
    }

    return recoveryFlow
  }

  // Recover the user with the given credentials
  async function recoverUser(
    flowID: string,
    body: UpdateRecoveryFlowBody,
    language: string,
  ) {
    body.csrf_token = csrfToken.value

    const languagePayload = {
      lang: language,
    }

    body.transient_payload = languagePayload

    let recoveryData: RecoveryFlow | undefined

    await ory
      .updateRecoveryFlow({ flow: flowID, updateRecoveryFlowBody: body })
      .then((response) => {
        recoveryData = response.data
        recoveryData.return_to = 'resetpassword'
      })
      .catch((error: any) => {
        // console.log('error updating recovery flow', error)

        if (error.response?.status && error.response.status === 422) {
          // This error indicates, that the verification was successful, but a browser redirect is needed. Because we use our own ui this is a success for us and we don't htrow an error.
          return recoveryData
        }

        throw error
      })

    return recoveryData
  }

  // Create the settings flow to modify the user settings
  async function createSettingsFlow() {
    let settingsData: SettingsFlow | undefined

    await ory
      .createBrowserSettingsFlow()
      .then((response) => {
        settingsData = response.data
        settingsData.return_to = 'login'
      })
      .catch((error: any) => {
        // console.log('error creating settings flow. ', error)
        throw error
      })

    // Get the csrf_token from the response by ranging over the ui.nodes and finding the csrf_token node
    if (settingsData && settingsData.ui?.nodes) {
      const csrfNode = settingsData.ui.nodes.find(
        (node) =>
          node.type === 'input' &&
          (node.attributes as UiNodeInputAttributes).name === 'csrf_token',
      )
      if (csrfNode) {
        csrfToken.value = (csrfNode.attributes as UiNodeInputAttributes).value
      }
    }

    return settingsData
  }

  // Complete the settings flow to modify the user settings
  async function completeSettingsFlow(flowid: string, newPw: string) {
    const updateBody: UpdateSettingsFlowBody = {
      method: 'password',
      password: newPw,
      csrf_token: csrfToken.value,
    }

    const updateSettingsData: FrontendApiUpdateSettingsFlowRequest = {
      flow: flowid,
      updateSettingsFlowBody: updateBody,
    }

    let settingsData: SettingsFlow | undefined

    await ory
      .updateSettingsFlow(updateSettingsData)
      .then((response) => {
        settingsData = response.data
      })
      .catch((error) => {
        // console.log('error updating settings flow', error)
        throw error
      })

    return settingsData
  }

  // Starts the changedPasswordTimer and lets it run for 8 seconds
  function startChangedPasswordTimer() {
    changedPasswordTimer.value = 8
    const interval = setInterval(() => {
      changedPasswordTimer.value--
      if (changedPasswordTimer.value === 0) {
        clearInterval(interval)
      }
    }, 1000)
  }

  return {
    isAuthenticated,
    authenticationMethod,
    isSessionExpired,
    loadSession,
    getLoginFlowData,
    getLogoutData,
    logoutUser,
    performLogout,
    resetSession,
    getRegisterFlowData,
    getVerificationFlowData,
    getRecoveryFlowData,
    loginUser,
    performLogin,
    registerUser,
    completeVerificationFlow,
    session,
    recoverUser,
    createSettingsFlow,
    completeSettingsFlow,
    startChangedPasswordTimer,
  }
})
