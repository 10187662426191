export const useUserStore = defineStore('user', () => {
  const userData: Ref<UserData | undefined> = ref()
  const loaded = ref(false)
  const passwordChanged = ref(false)
  const uploadAvatarState = ref(false)

  const name = computed(() =>
    [userData.value?.contacts.firstName, userData.value?.contacts.lastName]
      .filter(Boolean)
      .join(' '),
  )

  async function load(reload = false) {
    if (loaded.value && !reload) {
      return
    }
    try {
      const headers = useRequestHeaders(['cookie'])
      const data = await $fetch<UserData>('/guestworld-api/v1/users', {
        headers,
        credentials: 'include',
      })

      userData.value = data
    } catch (error) {
      console.error('Error while loading the user', error)
      throw error
    }
    loaded.value = true
  }

  // Load additional user data like reservations and documents
  async function loadAdditionalUserData(reload = false) {
    // Skip if no user data is present
    if (!userData.value) return
    // Skip if the accommodations and documents are already loaded
    if (
      !reload &&
      userData.value.reservations &&
      userData.value.reservations.length > 0 &&
      userData.value.reservations[0].accommodation &&
      userData.value.reservations[0].documents
    )
      return
    try {
      // Load the reservations for the user
      if (reload) {
        await load(true)
      }
      if (
        userData.value.reservations &&
        userData.value.reservations.length > 0
      ) {
        await Promise.all(
          userData.value.reservations.map(
            async (reservation: UserReservation) => {
              const accommodation = await useAccomm().getAccommodation(
                reservation.accommodationCode,
                useConfdata().language ?? 'en',
                null,
              )
              reservation.accommodation = accommodation
            },
          ),
        )

        // Load the documents for the reservation - this is only a temproray solution: TODO: remove this and use the documents from getUser call
        await Promise.all(
          userData.value.reservations.map(
            async (reservation: UserReservation) => {
              const completeReservation = await useReservation().getReservation(
                reservation.token,
                null,
              )
              reservation.documents = completeReservation.documents
            },
          ),
        )
      }
    } catch (error) {
      console.error('Error while loading additional user data', error)
      throw error
    }
  }

  // Registers the user on the guestworld API
  async function registerUser(email: string) {
    const response = await $fetch('/guestworld-api/v1/users', {
      method: 'POST',
      body: { email },
      credentials: 'include',
    }).catch((error) => {
      console.error('Error while registering the user', error)
      throw error
    })
    console.log('User registered', response)
  }

  // Updates the user with the given data
  async function updateUser(body: UpdateUserRequest) {
    const response = await $fetch('/guestworld-api/v1/users', {
      method: 'PUT',
      body,
      credentials: 'include',
    }).catch((error) => {
      console.error('Error while updating the user', error)
      throw error
    })
    console.log('🚀 ~ updateUser ~ response:', response)
    // Update was successful -> update the user ref
    if (response && userData.value) {
      userData.value.contacts.title = body.title
      userData.value.contacts.firstName = body.firstName
      userData.value.contacts.lastName = body.lastName
      userData.value.contacts.street = body.street
      userData.value.contacts.postcode = body.postcode
      userData.value.contacts.city = body.city
      userData.value.contacts.country = body.country
      userData.value.contacts.phone = body.phone
    }
  }

  // Deletes the user from the guestworld API and from ory
  async function deleteUser() {
    await $fetch('/guestworld-api/v1/users', {
      method: 'DELETE',
      credentials: 'include',
    }).catch((error) => {
      console.error('Error while deleting the user', error)
      throw error
    })

    // Reset the session
    useAuth().resetSession()
  }

  // Upload user avatar
  async function uploadAvatar(image: FormData) {
    await $fetch('/guestworld-api/v1/users/picture', {
      method: 'POST',
      body: image,
      credentials: 'include',
    }).catch((error) => {
      console.error('Error while uploading the avatar', error)
      throw error
    })
    await reloadUser(userData.value?.profileImg)
  }

  async function reloadUser(currentProfileImg?: string) {
    let attempt = 0

    while (attempt < 5) {
      await load(true)

      if (userData.value?.profileImg !== currentProfileImg) return

      await new Promise((resolve) => setTimeout(resolve, 1000))

      attempt++
    }
  }

  async function removeAvatar() {
    await $fetch('/guestworld-api/v1/users/picture', {
      method: 'DELETE',
      credentials: 'include',
    }).catch((error) => {
      console.error('Error while deleting the avatar', error)
      throw error
    })
    await load(true)
  }

  async function linkReservation(
    reservationToken: string,
    otp: string | undefined = undefined,
  ): Promise<{ data: string | null; error: string | null }> {
    try {
      const response = await $fetch<string>('/guestworld-api/v1/users/link', {
        method: 'POST',
        body: { reservationToken, otp },
        credentials: 'include',
        onResponse({ response }) {
          // If the status is 200, the linking was a success
          if (response.status === 200) {
            response._data = 'success'
          }
          // If the status is 202, the linking is in verification
          if (response.status === 202) {
            response._data = 'accepted'
          }
        },
      })
      return { data: response, error: null }
    } catch (e: any) {
      return { data: null, error: parseLinkErrorMessage(e.data.message) }
    }
  }

  const parseLinkErrorMessage = (message: string): string | null => {
    if (!message) return 'genericError'

    const jsonStartIndex = message.indexOf('{')
    const jsonString = message.substring(jsonStartIndex)

    if (isValidJson(jsonString)) {
      const obj = JSON.parse(jsonString)
      if (!obj.status) return 'genericError'

      const msg = obj?.status[0]?.message

      if (!msg) return 'genericError'
      const pattern1 = /^E-Mails of guest account \/ reservation do not match$/
      const pattern2 =
        /^Reservation with token [a-zA-Z0-9]+ has link to account$/

      /* Error patterns which we can use in future to improve user experience */

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const pattern3 = /^Reservation for token [a-zA-Z0-9]+ does not exist.$/
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const pattern4 = /^Multiple reservations found for token: [a-zA-Z0-9]+$/
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const pattern5 =
        /^E-Mail not found for guest account [a-zA-Z0-9]+ \/ reservation [a-zA-Z0-9]+$/

      if (pattern1.test(msg.trim())) {
        return 'emailMismatchError'
      }

      if (pattern2.test(msg.trim())) {
        return 'alreadyLinkedError'
      }
    }

    return 'genericError'
  }

  return {
    userData,
    load,
    loadAdditionalUserData,
    uploadAvatarState,
    registerUser,
    updateUser,
    deleteUser,
    uploadAvatar,
    removeAvatar,
    linkReservation,
    passwordChanged,
    name,
  }
})
