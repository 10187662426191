const translations = {
  errors: {
    guestWorld: {
      oops: 'Oops! Something went wrong.',
      title: 'An error occured',
      sorry:
        "We're sorry, but it seems there was an error while trying to process your request. Please try again later or check back in a moment.",
      message:
        'The token passed is invalid. Please check whether the URL you entered matches the URL you received by email. If this is the case, accessing the reservation management is currently not possible. In this case, please contact us by phone.',
      thanks: 'Thank you for your understanding!',
      goToStart: 'Go to start',
      goToHome: 'Go to homepage',
    },
  },
  components: {
    cookieConsent: {
      cookiesAccept: 'Accept',
      cookiesAcceptAll: 'Accept all',
      cookiesAnalyticalText:
        'The statistical analysis of your visit to our website helps us improve the website for you. For this purpose, cookies are set which make such an analysis technically possible.',
      cookiesAnalyticalTitle: 'Analytical cookies',
      cookiesConfigureHere: 'here',
      cookiesConfigureLink: 'Configure',
      cookiesConfirmChoice: 'Save selection',
      cookiesDecline: 'Decline',
      cookiesFunctionalText:
        'Some basic technical functions of this website are based on the use of cookies. For example, we store your watch list or your preferred currency.',
      cookiesFunctionalTitle: 'Functional cookies (always active)',
      cookiesInfotextAfter: '.',
      cookiesInfotextBefore:
        'To help you get the best from our website, we collect information regarding usage with the help of cookies. These cookies are necessary to ensure the functionality of our site. They can also be used for analysis (these cookies are used to make our website and our services more efficient for you as well as improving them) and for marketing purposes (both by us and by selected, trustworthy partners). By clicking on "Accept" you agree to the use of all cookies. If you choose not to, you can select the cookies you are happy to use',
      cookiesManagePreferences: 'Please select your preferences',
      cookiesMarketingText:
        'By setting marketing cookies, our marketing and advertising partners can show you personalised offers on other sites.',
      cookiesMarketingTitle: 'Marketing cookies',
      cookiesMoreInfoAfter: 'for more information about our privacy policy.',
      cookiesMoreInfoBefore: 'Click',
      cookiesMoreInfoHere: 'here',
      cookiesSettings: 'Cookie settings',
      cookiesTitle: 'We take good care of your data',
    },
  },

  precheckin: {
    components: {
      accommodationView: {
        accommodationView: {
          topRated: 'TOP-RATED',
          yourBooking: 'Your booking for {accommodationName}',
          location: 'Location',
          objectNumber: 'Accommodation Code',
          selectedDate: 'Selected arrival date',
          to: 'to',
          nights: 'nights',
        },
        featureList: {
          room_s: 'no rooms | one room | {count} rooms',
          bedroom_s: 'no bedrooms | one bedroom | {count} bedrooms',
          bathroom_s: 'no bathrooms | one bathroom | {count} bathrooms',
          pax_s: 'no guests | one guest | up to {count} guests',
        },
      },
      colors: {},
      generalInfobar: {
        text: 'Aufgrund von Wartungsarbeiten ist unsere Website vom 02.09.2023 22:00 Uhr MEZ bis zum 03.09.2023 19:00 Uhr MEZ nicht verfügbar.', // '42452'
      },
      footer: {
        footer: {
          copyright: 'HHD AG Schweiz',
          cookieSettings: 'Cookie Einstellungen',
          qualityCheckAlt: '33194',
          SSLCertificateAlt: '33195',
          memberMigros: '18093',
          migros: '18205',
        },
      },
      forms: {
        arrivalForm: {
          labels: {
            phone: 'Telefonnummer im Urlaub',
            arrivalDetails: 'Anreisedetails (Flugnummer, Ankunftszeit)',
            optional: 'Optional',
            email: 'Email',
            startTime: 'Start time',
            endTime: 'End time',
            travelType: 'Travel type',
            flightNumber: 'Flight number',
            ferryNumber: 'Ferry number',
            destination: 'Destination',
          },
          travelTypes: {
            train: 'Train',
            ferry: 'Ferry',
            bus: 'Bus',
            car: 'Car',
            airplane: 'Airplane',
            other: 'Other',
          },
          navigation: {
            back: 'Back',
            saveContinue: 'Save and Continue',
          },
          noAds: 'Wird nicht zu Werbezwecken verwendet',
          keyTimeFrame: 'Arrival Timeframe for key collection:',
          keyCollectionNote:
            'Arrivals outside of the above times must be confirmed in advance with the keyholder. You will receive the contact details of the keyholder with your travel documents as soon as you have completed the Pre-Checkin.',
          arrivalTime: 'Your estimated arrival time:',
          travelDetails: 'Your travel details:',
          travelDetailsInfo:
            'The keyholder can estimate your arrival time better if he/she knows where and when you will arrive at your holiday destination.',
          validation: {
            maxlen40: 'Must be less than 40 characters',
            forbiddenChars:
              "Please do not use any of the following symbols: <>'.",
            salutationRequired: 'Please select',
            firstnameRequired: 'Please enter your first name',
            lastnameRequired: 'Please enter your last name',
            streetRequired: 'Please enter your street',
            zipRequired: 'Please enter zip code',
            placeRequired: 'Please enter city name',
            countryRequired: 'Please choose country of residence',
            phoneRequired:
              'Please provide a telephone number for possible queries',
            phoneValidation:
              'Please enter a valid phone number (Only numbers allowed)',
            emailRequired: 'Please enter a valid email addres',
          },
        },

        customerForm: {
          required: '*=Required',
          contact: 'Customer',
          contactDetails:
            'Enter your contact details so that we can contact you with any questions and you can see your travel documents. Your personal information is strictly confidential.',
          optional: 'Optional',

          labels: {
            salutation: 'Salutation',
            ms: 'Ms',
            mr: 'Mr',
            firstname: 'First name',
            lastname: 'Last name',
            street: 'Street & nr',
            addressLine1: 'Street 2',
            zip: 'ZIP',
            place: 'City',
            country: 'Country',
            holidayContact: 'Phone (when on holiday)',
            mail: 'eMail',
            optional: 'Optional',
          },

          navigation: {
            back: 'Back',
            saveContinue: 'Save and Continue',
          },
          validation: {
            maxlen40: 'Must be less than 40 characters',
            forbiddenChars:
              "Please do not use any of the following symbols: <>'.",
            salutationRequired: 'Please select',
            firstnameRequired: 'Please enter your first name',
            lastnameRequired: 'Please enter your last name',
            streetRequired: 'Please enter your street',
            zipRequired: 'Please enter zip code',
            placeRequired: 'Please enter city name',
            countryRequired: 'Please choose country of residence',
            phoneRequired:
              'Please provide a telephone number for possible queries',
            phoneValidation:
              'Please enter a valid phone number (Only numbers allowed)',
            emailRequired: 'Please enter a valid email addres',
          },
        },

        errorModal: {
          close: 'Close',
          error:
            'An internal error has occurred. Please try again in a few minutes. If the error persists, contact our service hotline.',
        },

        paxForm: {
          adultNeeded: 'Add a grownup',
          heading: 'Persons',
          navigation: {
            back: 'Back',
            saveContinue: 'Save and continue',
          },
        },

        servicesForm: {
          groups: {
            payed: 'Inkludierte und bereits bezahlte Leistungen',
            bookable: 'Zusätzliche Leistungen',
            mandatory: 'Obligatorische Leistungen',
            deposit: 'Kaution',
          },
          total: 'TOTAL',
          taxes: 'inkl. MWSt.',
          payBeforeArrival:
            'Dieser Betrag wird erst bei Anreise von Ihrer Kreditkarte belastet.',
          actualUse:
            'To be paid to the local service provider depending on the actual occupancy and according to use.',
        },

        tokenForm: {
          heading: 'Pre Check-In',
          label: 'Enter your Booking ID',
          button: 'Check-In',
        },

        travellersForm: {
          heading: 'Travellers',
          add: 'Add another Traveller',
          traveller: '{count}. Traveller',
          maxlen: 'Must be less than {maxLength} characters',
          missingData: 'Please provide all required data',
          optional: 'Optional',
          required: 'Required field',

          maritalStatus: {
            u: 'unmarried',
            m: 'married',
            w: 'widowed',
            d: 'divorced',
          },
          passport: {
            PP: 'passport',
            ID: 'Identitätskarte',
            PA: 'Personalausweis',
          },
          salutation: {
            ms: 'Ms',
            mr: 'Mr',
          },
          labels: {
            salutation: 'Salutation',
            firstname: 'First Name',
            lastname: 'Last Name',
            birthday: 'Birthday',
            passportID: 'Passport ID',
            maritalStatus: 'Marital Status',
            passportType: 'Passport Type',
            passportIssueDate: 'Passport Issue Date',
            passportExpDate: 'Passport Expiry Date',
            street: 'Street',
            country: 'Country',
            zip: 'ZIP',
            place: 'Place',
            phone: 'Phone',
            countryOrigin: 'Country of Origin',
            countryOfBirth: 'Country of Birth',
            licensePlate: 'License Plate',
            nativePlace: 'Native Place',
            placeOfBirth: 'Place of Birth',
            profession: 'Profession',
            optional: 'Optional',
          },
          navigation: {
            back: 'Back',
            saveContinue: 'Save and Continue',
          },
        },
      },
      header: {
        header: {
          phoneAvailability: 'Call us 24/7',
        },
      },
      logo: {},
      pax: {
        pax: {
          adults: 'Adults',
          children: 'Children',
          babies: 'Babies',
          pensioner: 'Pensioners',
          teenager: 'Teenager',
        },
      },
      precheckinIntro: {
        precheckinIntro: {
          title: "Soon it's time!",
          paragraph1:
            'In order to speed up the arrival procedures and make it easier for you, we kindly ask you to use our {precheckin} service.',
          paragraph2: 'Once completed, you will receive your travel documents.',
          precheckin: 'pre-checkin',
        },
      },
      progressSteps: {
        progressSteps: {
          customer: 'Customer',
          arrival: 'Arrival',
          pax: 'Persons',
          travellers: 'Travellers',
          services: 'Services',
          checkin: 'Check-In',
        },
      },
      services: {
        creditCards: {
          newCard: 'Insert new card',
          noCreditCard:
            'Falls Sie keine Kreditkarte besitzen, kontaktieren Sie uns bitte, damit wir Ihnen die Reiseunterlagen zukommen lassen können.',
          successTitle:
            'Success! Your credit card information has been submitted successfully!',
          sucessSubTitle:
            'Thank you for your submission. If you have any questions or need further assistance, please feel free to contact our support team.',
          ccInfo:
            'Extra charges on-site as well as the deposit, where required, will automatically be charged from your credit card upon arrival',
        },
      },
      stylevariables: {},
    },
    layouts: {
      error: {
        reload: 'Try again',
        title: 'An error occurred',
        messages: {
          internal:
            'An internal error has occurred. Please try again in a few minutes. If the error persists, contact our service hotline.',
          invalidToken:
            'The token passed is invalid. Please check whether the URL you entered matches the URL you received by email. If this is the case, online cancellation is currently not possible. In this case, please contact us by telephone.',
          notFound: 'Sorry, the page you requested could not be found.',
        },
      },
    },
    pages: {
      precheckins: {
        _token: {
          mainTitle: 'Pre-Checkin',
          arrival: {
            heading: 'Arrival',
            important:
              'Wichtig, damit wir Sie in dringenden Fällen erreichen können.',
            arrivalTime:
              'Die verbindliche Anreisezeit für eine optimale Schlüsselübergabe ist in den Reiseunterlagen angegeben.',
          },
          checkin: {
            heading: 'Check-In',
            checkInfo: 'Please check all specified information and check in.',
            checkInConfirm:
              'Check-in has been successfully completed. Here’s an overview of previously provided information.',
            to: 'to',
            customer: {
              heading: 'Customer',
              fullName: 'Name',
              phone: 'Phonenumber',
              email: 'Email Address',
              address: 'Address',
              mr: 'Mr',
              ms: 'Ms',
            },
            arrival: {
              heading: 'Arrival Information',
              edit: 'Edit',
              contact: 'Holiday Contact',
              info: 'Arrival Information',
            },
            travellers: {
              heading: 'One Traveller|{count} Travellers',
              edit: 'Edit',
              mr: 'Mr',
              ms: 'Ms',
              travellers: 'Travellers',
            },
            services: {
              heading: 'Services',
              edit: 'Edit',
              includedService: 'Included or already paid service',
            },
            confirmation: {
              heading: 'Confirmation',
              success: 'Your Pre-Checkin was completed successfully.',
              back: 'Back to booking',
            },
            toBePayed: 'Vor Ort zu bezahlen',
            total: 'TOTAL',
            taxes: 'inkl. MWSt.',
            payBeforeArrival:
              'Dieser Betrag wird erst bei Anreise von Ihrer Kreditkarte belastet.',
            secureTransmission:
              'Ihre Daten werden absolut sicher per SSL-Verschlüsselung übertragen und vertraulich behandelt.',
            checkin: 'Check-In',
          },
          customer: {
            contact: 'Contact',
            contactB2b: 'Customer Information',
            contactDetails1:
              'Enter your contact details so that we can contact you with any questions and you can see your travel documents.',
            contactDetails2:
              'Your personal information is strictly confidential.',
          },
          expired: {
            text: 'The online pre-CheckIn can only be completed until one day before the arrival day. Please contact your key holder/contact on site. Please see your voucher/ travel documents for the contact details.',
          },
          pax: {
            heading: 'Persons',
            info: 'Please list the travelling persons',
          },
          services: {
            heading: 'Services',
            checkin: 'Confirm Check-In',
            secureTransmission:
              'Your data is absolutely secure transmitted via SSL encryption and is confidential.',
            navigation: {
              back: 'Back',
              saveContinue: 'Save and Continue',
              noValidCard: 'Please select a valid credit card',
            },
          },
          travellers: {},
        },

        iframepages: {
          failure: {
            error: 'An error occurred',
            retry: 'Try again',
          },
          successpending: {
            pleasewait:
              'Please wait, your data are being processed. This will take a while... ',
          },
        },
      },
    },
  },

  webccBooking: {
    components: {
      booking: {
        modalReservationError: {
          title: '19436',
          message: '39112',
          supportText: '18256',
          close: '38884',
        },
      },
      reservation: {
        checkoutBox: {
          vatincluded: '39083',
          total: '39085',
          totalamountpayable: '39086',
          erroronpricefetch: '39087',
        },
        modalPaymentResult: {
          success: {
            title: '38885',
          },
          error: {
            title: '38886',
          },
          buttons: {
            close: '38884',
          },
        },
        modalVerification: {
          title: '38597',
          code: {
            received: '38598',
            request: '38599',
          },
          form: {
            label: '38600',
            placeholder: '38601',
          },
          invalidCode: {
            error: '38602',
            message: '38603',
          },
          buttons: {
            cancel: '38604',
            actions: {
              cancel: '39088',
              modify: '39089',
              refund: '39090',
            },
          },
        },

        reservationCard: {
          accommodation: 'Accommodation',
          addToAccount: {
            alreadyLinkedError:
              "This reservation is already linked to an account. Please check if it's linked to another email you may have used, or contact support.",
            button: 'Add to your Account',
            description: 'Add this booking to your Interhome account',
            emailMismatchError:
              'The email address does not match the reservation. Please check your account or use the correct email address.',
            genericError:
              'Something went wrong while linking your booking. Please try again or contact support if the problem continues.',
            info: 'If you are not registered yet, you can do so by clicking the button. Please use the email address from your booking to ensure successful linking.',
            link: 'Click here to see your bookings',
            success: 'New booking was added to your account.',
          },
          location: 'Location',
          missingAccommodation: 'No accommodation information available.',
          nights: 'Overnight stays',
          person_s: 'one person|{count} persons',
          reservationnumber: 'Reservation number',
          selectedDate: 'Travel dates',
          states: {
            CA: 'Canceled',
            HC: 'House closing',
            OB: 'Own occupancy',
            OK: 'Confirmed booking',
            OP: 'Unconfirmed booking',
            RQ: 'Unconfirmed booking',
            WW: 'Unconfirmed booking',
            XX: 'Canceled',
            OS: 'Unconfirmed booking',
            CR: 'Unconfirmed booking',
            ER: 'Unconfirmed booking',
          },
          status: 'Booking status',
          yourBooking: 'Your booking',
          yourTrip: 'Your trip to {location}',
        },

        termsCheckbox: {
          accept: '38824',
          terms: '38825',
          privacyStatement: '38826',
        },
        cancellation: {
          cancelConditions: {
            from: '38593',
            to: '38594',
            day_s: '38595',
            percentage: '38596',
          },
          modalCancellation: {
            action: {
              title: '38606',
              costs: '38607',
              final: '38608',
            },
            cancelling: {
              title: '38609',
              message: '38610',
            },
            success: {
              title: '38611',
              paymentRequired: '38612',
              refund: '38613',
              evenBalance: '38664',
              refundNotPossible: '38614',
            },
            error: {
              title: '38615',
              message: '38616',
            },
            error401: {
              title: '38615',
              message: '39281',
            },
            buttons: {
              close: '38617',
              cancel: '38618',
              perform: '38619',
              paymentLink: '38620',
            },
            loading: {
              title: '38665',
              message: '38666',
            },
          },
        },
        pax: {
          modalCheckoutPax: {
            action: {
              title: '39091',
              message: '39092',
            },
            loading: {
              title: '39093',
              message: '39094',
            },
            success: {
              title: '39095',
              message: '39096',
            },
            error: {
              title: '39097',
              message: '39098',
            },
            error401: {
              title: '39097',
              message: '39281',
            },
            buttons: {
              action: '39099',
              close: '39100',
              cancel: '39101',
              back: '39102',
            },
            saving: {
              title: {
                modifyService: '39103',
              },
            },
          },
          pax: {
            numberAdults: '39065',
            numberChildren: '39066',
            numberBabies: '39067',
            adultsAgeRange: '39138',
            childrenAgeRange: '39079',
            babiesAgeRange: '39080',
          },
        },
        payment: {
          payment: {
            choosePayment: '16433',
            total: '16425',
            partial: '38815',
            CCpartialInfo: '28856',
            PPpartialInfo: '28858',
            ECVpartialInfo: '37809',
            IDpartialInfo: '28859',
            CC: '18146',
            PP: '28900',
            ECV: '37808',
            ID: '16271',
            TW: 'Twint', // 40817 // Neu
            payNow: '38848',
          },
        },
        refund: {
          modalCoupon: {
            patience: '38624',
            created: '38625',
            email: '38626',
            error: '38627',
            error401: '39281',
            buttonClose: '38628',
            title: {
              loading: '38621',
              success: '38622',
              error: '38623',
            },
          },
          modalIban: {
            title: '38629',
            explanation: '38630',
            placeholder: '38631',
            success: '38632',
            refund: '38633',
            error: '38634',
            error401: '39281',
            buttons: {
              submit: '38635',
              close: '38636',
            },
          },
        },
        services: {
          invoiceTotalAmount: {
            total: '39085',
            erroronpricefetch: '39104',
          },
          modalCheckoutServices: {
            action: {
              title: '39105',
              message: '39106',
            },
            loading: {
              title: '39107',
              message: '39108',
            },
            success: {
              title: '39109',
              message: '39110',
            },
            error: {
              title: '39111',
              message: '39112',
            },
            error401: {
              title: '39111',
              message: '39281',
            },
            buttons: {
              action: '39113',
              close: '39114',
              cancel: '39115',
              back: '39116',
            },
            saving: {
              title: '39117',
            },
          },
          rentalPrice: {
            title: '39054',
            discounts: '39118',
            promotion: '39187',
            discount: '39188',
          },
          servicePosition: {
            price: {
              each: '39119',
              explanations: {
                U1: '39120',
                UD: '39121',
                UW: '39122',
                P1: '39123',
                PD: '39124',
                UH: '39196',
                PU: '39197',
                K1: '39198',
                M1: '39199',
                X1: '39200',
                LX: '39202',
              },
              rules: {
                U1: '39125',
                UD: '39126',
                UW: '39127',
                P1: '39128',
                PD: '39129',
                UH: '39203',
                PU: '39205',
                K1: '39204',
                M1: '39206',
                X1: '39207',
                LX: '39209',
              },
            },
          },
        },
      },
    },

    pages: {
      reservations: {
        pageTitle: 'Payment status',

        overview: {
          heading: 'Manage your booking here',
          ifQuestions:
            'Please contact your booking provider if you have any questions about your initial payment.',
          yourTrip: 'Your trip to {location}',

          cancellation: {
            button: 'To the cancellation',
            message: 'Here you have the possibility to cancel your booking.',
            title: 'Cancellation',
          },

          chekin: {
            complete: {
              button: 'Edit your Online Check-In',
              info: "You've already completed the Online Check-In.",
              title: 'Compulsory Online Check-In',
            },
            incomplete: {
              button: 'Check-In now',
              info: 'Please provide the legally required data before arrival.',
              title: 'Compulsory Online Check-In',
            },
            unavailable: {
              button: 'Check-In now',
              info: 'Please provide the legally required data before arrival.',
              note: 'Available from {available_date}',
              title: 'Compulsory Online Check-In',
            },
          },

          pax: {
            button: 'Change',
            message: 'Here you can change the number of travellers.',
            status: 'Currently booked:',
            title: 'Persons travelling',
          },

          precheckin: {
            complete: {
              button: 'Edit your arrival details',
              info: 'You have already entered your arrival details.',
              title: 'Required Information',
            },
            incomplete: {
              button: 'Specify arrival details now',
              info: 'In order to prepare your arrival, we need additional information.',
              title: 'Required Information',
            },
            unavailable: {
              button: 'Specify arrival details now',
              info: 'In order to prepare your arrival, we need additional information.',
              note: 'Available from {available_date}',
              title: 'Required Information',
            },
          },

          refund: {
            button: 'To the Select refund option',
            message: 'We could not automatically refund your deposit.',
            title: 'Refund',
          },

          services: {
            button: 'To the cancellation',
            message: 'Here you can select or deselect services.',
            title: 'Additional services',
          },

          status: {
            alreadyPaid: 'Already paid',
            amountDue: 'Amount due',
            button: 'Pay',
            dueDateFinalpayment: 'Final payment due on',
            dueDatePayment: 'Payment due on',
            dueDatePrepayment: 'Down payment due on',
            evenSaldo: 'The reservation has been paid in full.',
            finalpaymentAmount: 'Final payment amount',
            ifQuestions:
              'Please contact your booking provider if you have any questions about your initial payment.',
            invoiceAmount: 'Invoice amount',
            negativeSaldo: 'We will refund you the amount of {saldo}.',
            positiveSaldo: 'A payment of {saldo} is still outstanding.',
            prepaymentAmount: 'Down payment amount',
            title: 'Payment status',
          },

          token: {
            button: 'Submit',
            message:
              'The token is the last part of the cancellation link which was sent to you by E-Mail',
            title: 'Please enter your token',
          },

          voucher: {
            keyInObject:
              'The handover of keys takes place (in person or in a key safe) directly at your holiday home.',
            keySafeInformation:
              'Key collection will take place at the stated time. If, for any reason this is not possible, please inform your keyholder in advance. If you do not, we cannot guarantee key collection. Keysafe Code: {KeySafeCode}',
            arrivalInfo: {
              title: 'Travel overview',
              arrival: 'Arrival',
              departure: 'Departure',
              till: 'till',
              arrivalNote:
                'Arrivals outside the times specified above must be expressly confirmed by the key holder, as he is generally not obliged to be available outside these times.',
            },
            accomodation: {
              title: 'Your holiday accommodation',
              info: 'Accommodation information',
              ref: 'Reference No.:',
              home: 'Holiday home:',
              address: 'Address',
            },
            geo: {
              coordinates: 'Coordinates',
              link: 'See on a map',
              lat: 'Latitude:',
              long: 'Longitude:',
            },
            keyCollection: {
              title: 'Key handover (Reception and Return)',
              keyHolder: 'Keyholder',
              tel: 'Phone:',
              email: 'email:',
            },
          },
        },
        pax: {
          adults: 'Adults',
          adultsAgeRange: 'from 18 years',
          babies: 'Babies',
          babiesAgeRange: 'until 24 months',
          back: 'Back to booking',
          children: 'Children',
          childrenAgeRange: '2-17 years',
          choice: 'Please choose',
          htmlTitle: 'Persons travelling',
          maxPax: 'Max. {count} guests (adults and children) can travel.',
          persons: 'Guest information',
          savechanges: 'Confirm change',
        },
        services: {
          yourTrip: 'Ihre Reise nach {location}', // '42060'
          htmlTitle: 'Additional services',
          book: 'Book',
          blocks: {
            rentalprice: 'Rental price',
            invoice: 'Additional costs on invoice',
            onplace: 'Additional costs on site',
          },
          types: {
            Y1Y2: '39057',
            Y4: '39058',
            Y5: '39059',
            Y6: '39060',
            N1N2: '39061',
            N1: '39062',
            N2: '39063',
            N4: '39064',
          },
          back: 'Back to booking',
        },
      },
      cancellation: {
        yourTrip: 'Ihre Reise nach {location}', // '42060'
        back: '38558',
        heading: '38559',
        htmlTitle: '38560',
        button: '38568',
        isCancelled: '38569',
        cancellationNotPossible: '38570',
        costs: {
          title: '38561',
          totalPrice: '38562',
          current: '38563',
          payed: '38564',
          saldo: '38565',
          balancePositive: '38566',
          balanceNegative: '38567',
        },
      },
    },
  },
  reservations: {
    header: {
      logoCaption: 'Ferienhäuser & Wohnungen',
      phoneAvailability: 'Persönlich für Sie da!',
    },
    footer: {
      SSLCertificateAlt:
        'SSL Certificate - Sichere Bezahlung über SSL Verschlüsselung',
      memberMigros: 'Ein Unternehmen der Migros Gruppe',
      migros: 'MIGROS',
      qualityCheckAlt: 'HHD Quality Check',
      qualityText: 'Ferienhäuser und Ferienwohnungen',
      qualityTitle: "40'000 qualitätsgeprüfte",
      securityText: 'über SSL Verschlüsselung',
      securityTitle: 'Sichere Bezahlung',
      supportText: 'Unser Team berät Sie jederzeit gerne',
      supportTitle: '24h / 7 Tage Service Hotline',
    },

    pages: {
      cancellation: {
        back: 'Back to booking',
        button: 'Cancel booking',
        cancellationNotPossible:
          'This booking cannot be cancelled via the website. Please contact the supplier you booked with.',
        costs: {
          balanceNegative: 'in your favour',
          balancePositive: 'in our favour',
          current: 'Cancellation costs today',
          payed: 'Already paid',
          saldo: 'Balance (after cancellation)',
          title: 'Cost overview',
          totalPrice: 'Booking total',
        },
        heading: 'Here you can cancel your booking',
        htmlTitle: 'Cancellation',
        isCancelled: 'Your booking has been successfully cancelled.',
        title: 'Cancellation policy',
        yourTrip: 'Your trip to {location}',
      },

      overview: {
        cancellation: {
          button: 'To the cancellation',
          message: 'Here you have the possibility to cancel your booking.',
          title: 'Cancellation',
        },
        heading: 'Manage your booking here',
        precheckin: {
          button: 'To the pre-check-in',
          message:
            'To make the arrival formalities quicker and more convenient for you, we kindly ask you to use our pre-check-in.',
          title: 'Pre-check-in',
        },
        refund: {
          button: 'Select refund option',
          message: 'We could not automatically refund your deposit.',
          title: 'Refund',
        },
        status: {
          button: 'Pay',
          evenSaldo: 'The reservation has been paid in full.',
          negativeSaldo: 'We will refund you the amount of {saldo}.',
          positiveSaldo: 'A payment of {saldo} is still outstanding.',
          title: 'Payment status',
        },
        token: {
          button: 'Submit',
          message:
            'The token is the last part of the cancellation link which was sent to you by E-Mail',
          title: 'Please enter your token',
        },
      },

      payment: {
        back: 'Back to booking',
        errors: {
          payment: 'An error occurred during the payment process.',
        },
        heading: 'Payment',
        htmlTitle: 'Payment',
        noOpenPayments: 'No open payments',
        success: 'Payment successful',
        yourTrip: 'Your trip to {location}',
      },

      precheckin: {
        back: 'Back to booking',
        heading: 'Pre-check-in',
      },

      refund: {
        back: 'Return to booking',
        button: 'Select bindingly',
        explanation: 'Please select your preferred form of refund.',
        heading: 'Refund',
        noInfoNeeded:
          'Currently we do not need any further information for a refund.',
        options: {
          coupon: {
            message: 'Redeemable within 5 years',
            title: 'Voucher',
          },
          transfer: {
            message: 'Please have your account number (IBAN) ready.',
            title: 'Bank transfer',
          },
        },
        title: 'Refund options',
      },

      review: {
        options: {
          bad: 'Terrible',
          good: 'Good',
          inadequate: 'Poor',
          no: 'No',
          noComment: 'NA',
          satisfactory: 'Average',
          veryGood: 'Very good',
          yes: 'Yes',
        },
        validation: {
          maxlen40: 'Must be less than 40 characters',
          maxlen500: 'max. 500 signs for questions and special requests',
          maxlen80: 'Must be less than 80 characters',
          minlen20: 'Write your review (20 character minimum)',
          required: 'Please fill in',
        },
        rating: {
          awayWith: {
            familyCircle: 'Extended Family',
            familyKids: 'Young Children',
            familyTeens: 'Teenagers',
            friends: 'Friends',
            group: 'Large Group',
            others: 'Other',
            pleaseChoose: 'Select one',
            spouse: 'Spouse/Partner',
            subheading: 'Traveled with',
            unaccompanied: 'Solo',
          },
          heading: 'Rate this vacation rental',
          reviewTitle: 'Title your review',
          symbolsLeft: 'Remaining characters ',
        },
        recommend: {
          heading: 'Would you recommend this vacation rental to a friend?',
          heading_ic: 'Would you recommend Interchalet to a friend?',
          heading_ih: 'Would you recommend Interhome to a friend?',
          improvements: 'What can we do to improve your experience?',
          likely: 'extremely likely',
          minlen20: 'Answer must include at least 20 characters.',
          unlikely: 'not at all likely',
        },
        bookingArrival: {
          bookingProblems: 'How easy was it to book the property?',
          heading: 'Booking and arrival',
          helpfulDirections: 'How helpful were the directions to the property?',
          keyDelivery: 'How easy was key pickup/access to the property?',
        },
        duringStay: {
          bedsMattresses: 'Bedding & mattresses',
          cleanlinessBathroom: 'Bathroom cleanliness',
          customerService: 'On-site customer service / friendliness',
          furniture: 'Condition of the furniture',
          heading: 'During your stay',
          kitchenEquipment: 'Kitchen & utensils',
          otherServices: 'Other facilities & amenities',
          overallCleanliness: 'Cleanliness overall',
          overallCustomerService: 'Overall customer service',
          overallState: 'Overall condition of the property',
        },
        expectations: {
          costEffectiveness: 'Was it a good value for the price paid?',
          expectionsMet: 'Was the property as advertised?',
          heading: 'Meeting your expectations',
          recommendLocation:
            'Would you recommend the location of this property?',
          wifi: 'Did the property offer wi-fi/internet access?',
        },
        recommendGroups: {
          children: 'Families with young children',
          friends: 'Friends getaway',
          groups: 'Large groups',
          handicapped: 'People with disabilities',
          heading: 'Would you recommend this property for these groups?',
          pets: 'People with pets',
          romantic: 'A romantic getaway',
          seniors: 'Older travelers',
          singles: 'Young singles',
        },
        submit: {
          conditions: ' I have read and accept the {guidelines}',
          conditionsTwo: 'customer review policy.',
          error: 'An error occurred. Please try again later.',
          noOfficialComplaint:
            'This review does not constitute an official complaint to the Interhome customer services team.',
          sendReview: 'Submit your review',
          success: 'The data has successfully been submitted. Thank you!',
        },
      },
    },
  },

  guestWorld: {
    footer: {
      SSLCertificateAlt:
        'SSL Certificate - Sichere Bezahlung über SSL Verschlüsselung',
      conditions: 'AGB',
      imprint: 'Impressum',
      memberMigros: 'Ein Unternehmen der Migros Gruppe',
      migros: 'MIGROS',
      privacy: 'Datenschutz',
      qualityCheckAlt: 'HHD Quality Check',
      qualityText: 'Ferienhäuser und Ferienwohnungen',
      qualityTitle: "40'000 qualitätsgeprüfte",
      securityText: 'über SSL Verschlüsselung',
      securityTitle: 'Sichere Bezahlung',
      supportText: 'Unser Team berät Sie jederzeit gerne',
      supportTitle: '24h / 7 Tage Service Hotline',
      copyright: 'HHD AG Schweiz',
      cookieSettings: 'Cookie Einstellungen',
    },
    footerB2C: {
      components: {
        footer: {
          about: {
            heading: {
              ih: 'About Interhome',
            },
          },
          contact: {
            availability:
              'Our customer service professionals are available 24/7',
            heading: 'Contact',
            support: 'We are here to help you with any questions and advice.',
          },
          cookieSettings: 'Cookie settings',
          copyright: '© HHD AG Switzerland {year}',

          partner: {
            domains: 'Change country and language',
            heading: 'Partnerships',
            migros: 'A member of the Migros group',
          },
          payment: {
            ECV: 'chèque-vacances',
            ID: 'iDeal',
            INV: 'Payment on invoice',
            MAST: 'MasterCard',
            PAYPAL: 'PayPal',
            TW: 'TWINT',
            VISA: 'VISA',
            encrypted:
              'All data is encrypted. Verified by a Trustworthy third party Registration Authority.',
            heading: 'Payment methods',
          },
          siteFeedback: '',
          social: {
            heading: 'Follow Us',
          },
        },
        site: {},
      },
    },
    header: {
      logoCaption: 'Ferienhäuser & Wohnungen',
      phoneAvailability: 'Persönlich für Sie da!',
      hereForYou: "We're here for you!",
      contactUs: 'Contact us',
    },
    headerB2C: {
      availability: 'Persönlich für Sie da!', // "18088"
      contactUs: 'Kontaktieren Sie uns', // "16607"
      hereForYou: 'Wir sind für Sie da', // 42206
      faq: 'FAQ', // "39659"
      labels: {
        favorites: 'Merkliste', // "18208"
        history: 'Zuletzt angesehen', // "16644"
        language: 'Sprache', // "16642"
        currency: 'Währung', // "16557"
        menu: 'Menü', // "20275"
        signIn: 'Anmelden',
      },

      headerUserButton: {
        myAccount: 'My account',
        myBookings: 'My bookings',
        user: 'User',
        logOut: 'Log out',
      },
    },

    components: {
      auth: {
        register: {
          RegisterForm: {
            registerHeading: 'Register to your Interhome account',
            haveAccount: 'Already have an account?',
            goToSignIn: 'Sign in',
            unknownError: 'An unknown error occurred.',
          },
          RegisterFormEmail: {
            email: 'Email',
            continue: 'Continue with email',
            emailError: 'Invalid email address',
          },
          RegisterFormPassword: {
            password: 'Password',
            confirmPassword: 'Confirm password',
            passwordHint: 'At least 8 characters',
            noMatch: 'The password does not match.',
            create: 'Create account',
            unknownError: 'Could not register the user. Unknown error.',
            alreadyUsedError:
              'This email is already in use by another account.',
            databreachError:
              'The password has been found in data breaches, please use a stronger one.',
          },
          RegisterCodeVerification: {
            check: 'Check your Inbox',
            checkDescription:
              'Just one quick check to make sure you´re really you',
            weSent: 'We sent a verification code to ',
            confirm: 'Confirm the code',
            confirmProgress: 'Confirming the code',
            invalidCode: 'Please enter a valid code.',
            expiredCode: 'The code has expired. Please request a new one.',
            unknownError: 'An unknown error occurred.',
            noEmail: 'Didn’t receive the email?',
            checkSpam: 'remember to check your spam',
            checkSpamInfo: 'Remember to check your spam.',
            resend: 'Send again',
            codeSent: 'Code sent.',
            emailVerifiedMessage: 'Your email has been verified',
          },
          SocialSignUps: {
            initError:
              'Failed to initialize social sign-ins. Please reload the page.',
          },
        },
        StepBackButton: {
          back: 'Back',
        },
        login: {
          LoginForm: {
            signInTitle: 'Sign in to your Interhome account',
            email: 'Email',
            password: 'Password',
            forgotPassword: 'Forgot password?',
            signIn: 'Sign in',
            newToIH: 'New to Interhome?',
            register: 'Register',
            loginError: 'We don`t recognize this email and password. ',
            unknownError: 'An unknown error occurred.',
          },
          UserAgreement: {
            signInAgreement:
              'By signing in or creating an account, you agree with our ',
            // signInAgreementPP: 'By signing in or creating an account, you agree with our PRIVACY_POLICY_PLACEHOLDER .',
            privacyPolicy: 'Privacy Policy',
            signInAgreementEnd: '.',
          },
          SocialSignIns: {
            initError:
              'Failed to initialize social sign-ins. Please reload the page.',
          },
        },
        recover: {
          ForgotPasswordForm: {
            forgotpw: 'Forgot your password?',
            dontWorry:
              'Don’t worry! We’ll send you a link to reset it. Please enter your email address you’ve used to sign in.',
            email: 'Email',
            sendReset: 'Send reset link',
            checkInbox: 'Check your inbox',
            justSent:
              'We just emailed a reset password link to {email}. It might take a few minutes to arrive',
            backToSignIn: 'Back to sign in',
            password: 'Password',
          },
          ResetPasswordForm: {
            setPassword: 'Set a new password',
            reset: 'Set new password',
            newPassword: 'Password',
            confirmPassword: 'Confirm password',
            resetError: 'Failed to reset password. ',
            passwordHint: 'At least 8 characters',
            noMatch: 'The password does not match.',
          },
          RecoverCodeVerification: {
            check: 'Check your Inbox',
            checkDescription:
              'Just one quick check to make sure you´re really you',
            weSent: 'We sent a verification code to ',
            confirm: 'Confirm the code',
            confirmProgress: 'Confirming the code',
            invalidCode: 'Please enter a valid code.',
            expiredCode: 'The code has expired. Please request a new one.',
            unknownError: 'An unknown error occurred.',
            noEmail: 'Didn’t receive the email?',
            checkSpam: 'remember to check your spam',
            checkSpamInfo: 'Remember to check your spam.',
            resend: 'Send again',
            codeSent: 'Code sent.',
          },
        },
      },
      myaccount: {
        profile: {
          AccountForm: {
            title: 'Title',
            address: 'Address',
            fullName: 'Full name',
            firstName: 'First name',
            lastName: 'Last name',
            street: 'Street & nr',
            postcode: 'Postcode',
            city: 'City',
            country: 'Country',
            phone: 'Phone (when on holiday)',
            email: 'Email',
            password: 'Password',
            passwordHint:
              'We will send you a verification code to change your password',
            sendCode: 'Send code',
            labels: {
              ms: 'Ms',
              mr: 'Mr',
            },
            validation: {
              forbiddenChars: "Please do not use the characters <>'.",
              nameValidation: "Please do not use the characters <>'.",
              addressValidation: "Please do not use the characters <>'",
              zipValidation: 'Please enter a valid zip code.',
              maxlen40: 'Must be less than 40 characters',
              phoneValidation:
                'Please enter a valid phone number (Only numbers allowed)',
              emailValidation: 'Please enter a valid email address',
            },
          },
          AccountFormField: {
            save: 'Save',
            verified: 'verified',
            notVerified: 'not verified',
            plsFill: 'Please fill in',
            saveError: 'This data cannot be saved. Please try again.',
            validation: {
              forbiddenChars: "Bitte verwenden Sie die Zeichen <>' nicht.", // '18231'
              zipValidation: 'Bitte geben Sie eine gültige Postleitzahl an', // '40944'
              maxlen40: 'Must be less than 40 characters',
              phoneValidation:
                'Please enter a valid phone number (Only numbers allowed)',
            },
          },
          UserCard: {
            user: 'User',
          },
          UploadAvatarButton: {
            uploadPhoto: 'Upload a photo',
            removePhoto: 'Remove photo',
          },
          UploadAvatarModal: {
            positionPhoto: 'Position & size profile photo',
            note: 'Note: Please upload a picture less than 1 MB.',
            upload: 'Upload photo',
            save: 'Save',
            cancel: 'Cancel',
            error: 'Your photo upload failed. Please try again.',
            tryAgain: 'Try again',
            imgLimit: 'File too large. Please upload a file under 1 MB.',
            success: 'Changes have been saved',
            fileType:
              'Please upload a file of one of these file types: {filetypes}',
          },
          EmailReverificationBox: {
            emailNotVerified:
              'Please verify your email. We’ll send you a verification code to',
          },
          AccountDeleteModal: {
            deleteTitle: 'Delete my account',
            deleteMessage:
              'Please confirm that your Interhome account and all associated data will be deleted irreversibly.',
            cancel: 'Cancel',
            confirm: 'Confirm',
            deleteError: 'Failed to delete account.',
          },
        },
        MyAccountMobile: {
          myAccount: 'My account',
          personalInfo: 'Personal info',
        },
        bookings: {
          BookingCard: {
            viewRatings: 'view rating and reviews',
            noRatings: 'no rating and reviews',
            noRatingsYet: 'no rating and reviews yet',
            guests: 'guests',
            viewProperty: 'View property',
            bookAgain: 'Book again',
            manageBooking: 'Manage booking',
            completed: 'Completed',
            upcoming: 'Upcoming',
            cancelled: 'Cancelled',
            downloadDocuments: 'Download Documents',
            leaveRating: 'leave your rating and review',
            current: 'Current',
            houseUnavailable:
              'Accommodation {accomCode} is currently not available',
          },
          EmptyBookingCard: {
            nothingYet: 'There’s nothing to see here... yet!',
            searchnBook:
              'Search our website for holiday homes you like. Once you book it will be displayed here.',
            searchHomes: 'Search for holiday homes',
          },
          DocumentsModal: {
            downloadTitle: 'Download documents',
            downloadInfo:
              'Here you can download all your booking related documents once they are available',
            downloadInvoice: 'Download Invoice',
            downloadVoucher: 'Download Voucher',
            downloadPayConfirmation: 'Download payment confirmation',
            cancel: 'Cancel',
            downloadAll: 'Download all',
          },
          BookingLinkingVerification: {
            check: 'Check your Inbox',
            checkDescription:
              'Just one quick check to make sure you’re really you.',
            weSent:
              'A verification code has been sent to the email address you used for your booking. Please enter the code here to link your reservation.',
            confirm: 'Confirm the code',
            confirmProgress: 'Confirming the code',
            unknownError: 'An unknown error occurred.',
            noEmail: 'Didn’t receive the email?',
            checkSpam: 'remember to check your spam',
            checkSpamInfo: 'Remember to check your spam.',
            resend: 'Send again',
            codeSent: 'Code sent.',
          },
        },
        NavigationBar: {
          personalInfo: 'Personal info',
        },
      },
      WebccInterchaletBrandMergeToaster: {
        heading: 'Interchalet is now Interhome!',
        description:
          'Apart from the name change, everything else remains the same!',
        readMore: 'Read more',
      },
      AddToAccountToaster: {
        link: 'Click here to see your bookings',
        success: 'New booking was added to your account.',
        emailMismatchError:
          'The email address does not match the reservation. Please check your account or use the correct email address.',
        alreadyLinkedError:
          "This reservation is already linked to an account. Please check if it's linked to another email you may have used, or contact support.",
        genericError:
          'Something went wrong while linking your booking. Please try again or contact support if the problem continues.',
      },
      WebccOrDivider: {
        or: 'or',
      },
      tokenForm: {
        title: 'Please enter your token',
        message:
          'The token is the last part of the link which was sent to you by e-mail',
        button: 'Submit',
      },
    },
    pages: {
      myaccount: {
        bookings: {
          myBookings: 'My bookings',
          loadMore: 'Load more',
        },
        profile: {
          personalInfo: 'Personal info',
          enterContacts:
            'Enter your contact details so that we can contact you with any questions and you can see your travel documents. Your personal information is strictly confidential.',
          deleteNote: 'Deletion of your Interhome account',
          deleteAccount: 'Delete Account',
          passwordChangedMessage: 'Your password has been changed',
          usageInfo:
            'We use your name, address, e-mail address and telephone number according to Art. 6 (b) GDPR for the purpose of sending the information requested with this form and save it for the purpose of concluding a contract and, based on Art. 6 (1) (f) for sending further information about our offer. You will find more information relating to Art. 13 GDPR in our privacy policy.',
        },
      },
      callback: {
        registerError:
          'Error registering user by social sign in session. Please try again to sign in.',
      },
    },
  },

  plugins: {
    components: {
      Datepicker: {
        arrivalBeforeDeparture: 'Arrival day must be before departure day',
        arrivalDeparture_s:
          'Arrival and departure date (min. one night)|Arrival and departure date (min. {count} nights)',
        arrival_s:
          'Possible date of arrival (min. one night)|Possible date of arrival (min. {count} nights)',
        dateInPast: 'Selected day is in the past',
        departureAvailable: 'Departure possible',
        departureOnly: 'Departure only',
        noArrival: 'Available, arrival not possible',
        noDeparture: 'Departure not possible',
        noDepartureMaxStay_s:
          'No departure possible, maximum stay one night|No departure possible, maximum stay {count} nights',
        noDepartureMinStay_s:
          'No departure possible, minimum stay one night|No departure possible, minimum stay {count} nights',
        notAvailable: 'Not available',
        selectedArrival_s:
          'Your selected arrival date with a min. stay of one night|Your selected arrival date with a min. stay of {count} nights',
      },
    },

    form: {
      Textarea: {
        remainingChars: 'Verbleibende Zeichen: ',
      },
    },
  },
}

export default translations

export type { translations as TranslationsType }

export type RecursiveKeyOf<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<
    TObj[TKey],
    `${TKey}`
  >
}[keyof TObj & (string | number)]

type RecursiveKeyOfInner<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<
    TObj[TKey],
    `.${TKey}`
  >
}[keyof TObj & (string | number)]

type RecursiveKeyOfHandleValue<
  TValue,
  Text extends string,
> = TValue extends any[]
  ? Text
  : TValue extends object
    ? Text | `${Text}${RecursiveKeyOfInner<TValue>}`
    : Text
