<template>
  <WebccModal>
    <div
      class="config-content mx-auto my-0 flex w-[95%] max-w-3xl flex-col overflow-auto"
    >
      <header
        class="relative flex min-w-[300px] shrink-0 items-center justify-between rounded-t-md bg-bgr p-6"
      >
        <p class="text-xl font-medium">
          {{ $t(`${trPrefix}cookiesManagePreferences`) }}
        </p>

        <button
          class="pointer-events-auto relative inline-block h-6 w-6 shrink-0 grow-0 cursor-pointer select-none appearance-none rounded-xl border-0 bg-bgr-300 p-1 align-top outline-none"
          aria-label="close"
          @click="$emit('close')"
        >
          <svg
            class="h-4 w-4 text-txt-contrast"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </header>
      <section class="flex-1-auto min-w-[300px] overflow-y-auto bg-bgr-100 p-6">
        <div class="choice grid overflow-auto pb-3 md:pb-4">
          <div class="choice-title text-base font-bold text-txt">
            {{ $t(`${trPrefix}cookiesFunctionalTitle`) }}
          </div>

          <div class="choice-switch justify-self-end">
            <input
              id="switch1"
              type="checkbox"
              class="checkbox"
              disabled
              checked
            />
            <label for="switch1" class="switch disabled" />
          </div>

          <div class="choice-text max-w-2xl text-sm text-txt md:text-base">
            {{ $t(`${trPrefix}cookiesFunctionalText`) }}
          </div>
        </div>
        <div class="choice grid overflow-auto pb-3 md:pb-4">
          <div class="choice-title text-base font-bold text-txt">
            {{ $t(`${trPrefix}cookiesAnalyticalTitle`) }}
          </div>

          <div class="choice-switch justify-self-end">
            <input
              id="switch2"
              v-model="statsChoice"
              type="checkbox"
              class="checkbox"
              value="true"
            />
            <label for="switch2" class="switch" />
          </div>

          <div class="choice-text max-w-2xl text-sm text-txt md:text-base">
            {{ $t(`${trPrefix}cookiesAnalyticalText`) }}
          </div>
        </div>
        <div class="choice grid overflow-auto">
          <div class="choice-title text-base font-bold text-txt">
            {{ $t(`${trPrefix}cookiesMarketingTitle`) }}
          </div>

          <div class="choice-switch justify-self-end">
            <input
              id="switch3"
              v-model="otherChoice"
              type="checkbox"
              class="checkbox"
              value="true"
            />
            <label for="switch3" class="switch" />
          </div>

          <div class="choice-text max-w-2xl text-sm text-txt md:text-base">
            {{ $t(`${trPrefix}cookiesMarketingText`) }}
          </div>
        </div>
      </section>
      <footer
        class="flex min-w-[300px] shrink-0 flex-col-reverse gap-4 rounded-b-md border-b bg-bgr p-6 sm:flex-row sm:justify-end"
      >
        <WebccButton
          @click="sendChoice({ statistics: statsChoice, other: otherChoice })"
        >
          {{ $t(`${trPrefix}cookiesConfirmChoice`) }}
        </WebccButton>
        <WebccButton
          variant="theme"
          @click="sendChoice({ statistics: true, other: true })"
        >
          {{ $t(`${trPrefix}cookiesAcceptAll`) }}
        </WebccButton>
      </footer>
    </div>
  </WebccModal>
</template>

<script setup lang="ts">
const trPrefix = 'components.cookieConsent.'

const props = defineProps<{ statistics?: boolean; other?: boolean }>()

const emit = defineEmits<{
  (e: 'save-choice', value: Choice): void
  (e: 'close'): void
}>()

const statsChoice = ref(props.statistics)
const otherChoice = ref(props.other)

function sendChoice(values: Choice) {
  console.log('save Choice aus config', {
    statistics: values.statistics,
    other: values.other,
  })
  emit('save-choice', { statistics: values.statistics, other: values.other })
}
</script>

<style scoped>
.config-wrapper {
  /* TODO: How to do this in tailwind? */
  background-color: rgba(10, 10, 10, 0.625);
}
.config-content {
  /* TODO: How to do this in tailwind? */
  max-height: calc(100vh - 120px);
}

/* TODO: How to do this in tailwind? */
.choice {
  column-gap: 0.875rem;
  grid-template-columns: 4fr 1fr;
  grid-template-areas:
    'title switch'
    'text text';
}
@media (min-width: 960px) {
  .choice {
    column-gap: 1rem;
  }
}
.choice-title {
  grid-area: title;
}
.choice-switch {
  grid-area: switch;
}
.choice-text {
  grid-area: text;
}

/* https://medium.com/front-end-weekly/creating-a-toggle-switch-in-css-2d23e496d035 */
/* TODO: How to do this in tailwind? */
.checkbox {
  display: none;
}
.switch {
  @apply relative inline-block bg-bgr-300;
  width: 40px;
  height: 20px;
  border-radius: 20px;
}
.switch:not(.disabled) {
  @apply cursor-pointer;
}
.switch::after {
  @apply bg-bgr;
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: 1px;
  left: 1px;
  transition: all 0.5s;
}
.checkbox:checked + .switch::after {
  left: 20px;
}
.checkbox:checked + .switch {
  @apply bg-thm;
}
.checkbox:checked + .switch.disabled {
  @apply bg-thm;
  opacity: 0.4;
}
</style>
