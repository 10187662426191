export const useReservationStore = defineStore('reservation', () => {
  const paramsLang = useParams().language

  const reservation: Ref<Reservation | null> = ref(null)
  const accommodation: Ref<TranslatedAccommodation | undefined> = ref(undefined)
  const voucher: Ref<Voucher | null> = ref(null)
  const priceCheck: Ref<ReservationPriceCheck | null> = ref(null)
  const token: Ref<string | undefined> = computed(
    () => reservation.value?.token,
  )
  const resNumber: Ref<string | undefined> = computed(
    () => reservation.value?.number,
  )

  // Loads the reservation and its accommodation from the API
  async function load(token: string, isPrecheckin: boolean = false) {
    // Assign the params
    let params = {}
    if (isPrecheckin) {
      params = { precheckin: true }
    }

    try {
      reservation.value = await getReservation(token, params)
      if (paramsLang) {
        accommodation.value = await useAccomm().getAccommodation(
          reservation.value.accommodation,
          paramsLang,
          params,
        )
      } else {
        accommodation.value = await useAccomm().getAccommodation(
          reservation.value.accommodation,
          reservation.value.language,
          params,
        )
      }

      // Set the params token
      // useParams().all.token = token
    } catch (error) {
      console.error('Error while loading reservation and accommodation', error)
      throw error
    }

    const documentIsAvailable = (reservation.value?.documents || []).some(
      (x) => x.name && x.name.toLowerCase() === 'voucher',
    )

    if (
      documentIsAvailable &&
      reservation.value?.state !== 'XX' &&
      reservation.value?.state !== 'CA'
    ) {
      try {
        const voucherReq = await $fetch<Voucher>(
          `/webcc-api/v1/booking/reservations/voucher/${token}`,
        )

        if (voucherReq) {
          voucher.value = voucherReq
        }
      } catch (error) {
        console.info('Error while loading voucher', error)
      }
    }
  }

  // This only gets the reservation from the webcc-api
  async function getReservation(token: string, params: any) {
    return await $fetch<Reservation>(
      `/webcc-api/v1/booking/reservations/${token}`,
      { params },
    )
  }

  // Validates the authentication code for the reservation
  async function verifyAuthCode(code: string | null) {
    // Check if the reservation is present
    if (!reservation) {
      throw new Error('Reservation is not loaded')
    }

    if (!code) {
      throw new Error('No authcode provided')
    }

    await $fetch<Date>(
      `/webcc-api/v1/booking/reservations/${resNumber.value}/authcodes/${code}`,
    )
      // Currently we do not need the date, maybe in the future
      .catch((error) => {
        console.error('Error while verifiying the authcode', error)
        throw error
      })
  }

  // Requests an authentication code for the reservation
  async function requestAuthCode(
    action: string,
    traceid: string | null = null,
  ) {
    // Check if the reservation is present
    if (!reservation) {
      throw new Error('Reservation is not loaded')
    }

    if (!traceid) {
      traceid = ''
    }

    await $fetch<string>(
      `/webcc-api/v1/booking/reservations/${resNumber.value}/authcodes/${action}`,
      {
        method: 'POST',
        timeout: 90000,
        headers: {
          traceid,
        },
      },
    ).catch((error) => {
      console.error('Error while requesting the authcode', error)
      throw error
    })
  }

  // Updates the reservation
  async function update(
    lastModified: string,
    authCode: string,
    params: URLSearchParams = new URLSearchParams(),
  ) {
    // Check if the reservation is present
    if (!reservation) {
      throw new Error('Reservation is not loaded')
    }

    // Setting the headers
    const headers: HeadersInit = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-LastModified': lastModified,
      Authorization: authCode,
    }

    // Create queryObject to pass the params
    const query = getQueryObjectFromURLParams(params)

    const res = await $fetch<ReservationPriceCheck>(
      `/webcc-api/v1/booking/reservations/${resNumber.value}`,
      {
        method: 'PUT',
        query,
        headers,
        timeout: 20000,
      },
    ).catch((error) => {
      console.error('Error while updating the reservation', error)
      throw error
    })

    // Setting the price check
    priceCheck.value = res
    return res
  }

  async function cancel(params: URLSearchParams = new URLSearchParams()) {
    // Check if the reservation is present
    if (!reservation) {
      throw new Error('Reservation is not loaded')
    }

    // Create queryObject to pass the params
    const query = getQueryObjectFromURLParams(params)

    const res = await $fetch<CancelReservationResponse>(
      `/webcc-api/v1/booking/reservations/${resNumber.value}`,
      {
        method: 'DELETE',
        query,
        timeout: 10000,
      },
    ).catch((error) => {
      console.error('Error while cancelling the reservation', error)
      throw error
    })

    return res
  }

  // Refunds the reservation
  async function refund(
    body: RefundBody,
    params: URLSearchParams = new URLSearchParams(),
  ) {
    // Check if the reservation is present
    if (!reservation) {
      throw new Error('Reservation is not loaded')
    }

    // Create queryObject to pass the params
    const query = getQueryObjectFromURLParams(params)

    try {
      const textData = await $fetch<string>(
        `/webcc-api/v1/booking/reservations/${resNumber.value}/refund`,
        {
          method: 'POST',
          query,
          body,
        },
      )

      return JSON.parse(textData) as Promotion
    } catch (error) {
      console.error('Error while refunding the reservation', error)
      throw error
    }
  }

  async function checkPrice(params: URLSearchParams = new URLSearchParams()) {
    // Check if the reservation is present
    if (!reservation) {
      throw new Error('Reservation is not loaded')
    }

    // Create queryObject to pass the params
    const query = getQueryObjectFromURLParams(params)

    const textRes = await $fetch<string>(
      `/webcc-api/v1/booking/reservations/${resNumber.value}/checkPrice`,
      {
        query,
      },
    ).catch((error) => {
      console.error('Error while checking the price', error)
      throw error
    })

    return JSON.parse(textRes) as ReservationPriceCheck
  }

  async function saveReview(body: string) {
    // Check if the reservation is present
    if (!reservation) {
      throw new Error('Reservation is not loaded')
    }

    try {
      await $fetch('/webcc-api/v1/forms/reviews', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        method: 'POST',
        body,
      })
    } catch (error) {
      console.error('Error while submitting review. ', error)
      throw error
    }
  }

  return {
    token,
    reservation,
    accommodation,
    resNumber,
    load,
    verifyAuthCode,
    requestAuthCode,
    update,
    cancel,
    refund,
    checkPrice,
    voucher,
    getReservation,
    saveReview,
  }
})
