<template>
  <div
    class="md:container-fluid-lg fixed bottom-0 flex h-full w-full max-w-full justify-center overflow-y-auto px-6 sm:h-auto sm:-translate-y-9 sm:bg-transparent"
    :class="{ 'bg-bgr-800 bg-opacity-80': !configIsOpen }"
  >
    <div
      class="consent-banner my-8 max-h-[85vh] overflow-y-auto rounded bg-bgr p-6"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <p class="mb-4 font-medium text-lg sm:text-xl">
        {{ $t(`${trPrefix}cookiesTitle`) }}
      </p>

      <span>{{ $t(`${trPrefix}cookiesInfotextBefore`) }}</span
      >&nbsp;<span
        class="inline-block cursor-pointer font-medium underline hover:no-underline"
        @click="openConfig()"
        >{{ $t(`${trPrefix}cookiesConfigureHere`) }}</span
      >

      <span>{{ $t(`${trPrefix}cookiesInfotextAfter`) }}</span
      >&nbsp;<span>{{ $t(`${trPrefix}cookiesMoreInfoBefore`) }}</span
      >&nbsp;<span
        class="inline-block cursor-pointer font-medium underline hover:no-underline"
        @click="openPrivacypolicy()"
        >{{ $t(`${trPrefix}cookiesMoreInfoHere`) }}</span
      >&nbsp;<span>{{ $t(`${trPrefix}cookiesMoreInfoAfter`) }}</span>

      <div
        class="mt-6 flex flex-col items-center justify-start gap-4 sm:flex-row"
      >
        <WebccButton
          class="h-12 w-48"
          variant="theme"
          @click="sendChoice({ statistics: true, other: true })"
        >
          {{ $t(`${trPrefix}cookiesAccept`) }}
        </WebccButton>
        <span
          class="cursor-pointer underline transition-all hover:no-underline"
          @click="sendChoice({ statistics: false, other: false })"
          >{{ $t(`${trPrefix}cookiesDecline`) }}</span
        >
        <span
          class="cursor-pointer underline transition-all hover:no-underline"
          @click="() => openConfig()"
        >
          {{ $t(`${trPrefix}cookiesConfigureLink`) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'components.cookieConsent.'

defineProps<{
  configIsOpen: boolean
  openConfig: Function
  openPrivacypolicy: Function
}>()

const emit = defineEmits<{
  (e: 'save-choice', value: Choice): void
  (e: 'close'): void
}>()

const sm = useScreenStore().breakpoints.smallerOrEqual('sm')

onMounted(() => {
  if (sm.value) {
    useScreenStore().disableScrolling()
  }

  useScreenStore().onResize.push(resizeMethod)
})

onBeforeUnmount(() => {
  if (sm.value) {
    useScreenStore().enableScrolling()
  }
  useScreenStore().onResize = useScreenStore().onResize.filter(
    (f) => f !== resizeMethod,
  )
})

function sendChoice(values: Choice) {
  console.log('save Choice aus Banner', {
    statistics: values.statistics,
    other: values.other,
  })
  emit('save-choice', { statistics: values.statistics, other: values.other })
}

function resizeMethod() {
  if (sm.value) {
    useScreenStore().disableScrolling()
  } else {
    useScreenStore().enableScrolling()
  }
}
</script>

<style scoped>
.consent-banner {
  /* TODO: How to do this in tailwind? */
  box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.12);
}
</style>
